<template>
    <v-autocomplete
        v-model="computedValue"
		v-bind="$attrs"
        :loading="isLoading"
        :items="items"
        :label="$props.label"
        :placeholder="$props.placeholder"
        :rules="$props.rules"
        :item-text="getItemTextLabel" 
        prepend-icon="fas fa-user" 
        validate-on-blur
        :required="false"
        clearable
        return-object
        :search-input.sync="searchAgentsQuery"
    />
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    props: ['value', 'label', 'placeholder', 'filters', 'rules', 'division', 'addSelf', 'useCachedItems'],
    data: function() {
        return {
            isLoading: false,
            searchAgentsQuery: null,
            items: [],
            cachedItems: [],
        }
    },
    computed: {
        computedValue: {
            get() { return this.value },
            set(v) { this.$emit('input', v) }
        }
    },
    methods: {
        getItemTextLabel: function(item) {
            return (item.AgentName + " - " + item.LeadershipLevel + ' (' + item.AgentCode + ')')
        },
        loadItems: debounce(function() {
            this.isLoading = true
			if (!this.searchAgentsQuery) {
				this.items = this.useCachedItems ? [...this.cachedItems] : []
			}
            if (!this.searchAgentsQuery || this.searchAgentsQuery.length < 3 || this.computedValue) {
                this.isLoading = false
                return
            }
            const options = {
                search: this.computedValue?.AgentCode || this.searchAgentsQuery,
                division: this.division,
                ...this.filters,
				itemsPerPage: 'all',
            }
            QuilityAPI.getSymmetryAgentSearch(options)
				.then((json) => {
					if (this.addSelf) {
						json.data.push({
							AgentName: this.user.Agent.AgentName,
							LeadershipLevel: this.user.Agent.LeadershipLevel,
							AgentCode: this.user.Agent.AgentCode,
						})
					}
					this.items = this.searchAgentsQuery ? json.data : []
					if (!this.computedValue) {
						this.cachedItems = [...json.data]
					}
					this.isLoading = false
				})
        }, 250),
    },
    watch: {
        searchAgentsQuery: 'loadItems',
        filters () {
			this.items = []
			this.cachedItems = []
			this.loadItems()
		},
    }
}
</script>
