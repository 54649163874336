<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agent-accounts-admin-submenu></q-agent-accounts-admin-submenu>
        <v-row class="mx-5">
            <v-col cols=12>
                <q-agent-accounts-data-table title="Agent Account Balances">
                </q-agent-accounts-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QAgentAccountsDataTable from '@/components/datatables/AgentAccounts/QAgentAccountsDataTable.vue';
import QAgentAccountsAdminSubmenu from '@/components/navigation/Submenus/QAgentAccountsAdminSubmenu.vue';

export default {
    data() {
        return {
            search: '',
        }
    },
    components: {
        QAgentAccountsDataTable,
        QAgentAccountsAdminSubmenu
    },
    methods: {

    },

}
</script>