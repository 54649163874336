<template>
    <div>
        <v-list dense>
            <v-list-item v-for="(writing_number) in writing_numbers" :key="writing_number.ID">
                <v-list-item-content>
                    <v-list-item-title class="carrier-name">
                        <span>{{ writing_number.CarrierName}}</span>
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-tooltip left color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on" v-clipboard:copy="writing_number.WritingNumber" v-clipboard:success="handleCopyStatus" class="ml-2">#{{ writing_number.WritingNumber}}</v-btn>
                        </template>
                        <span>Click to copy</span>
                    </v-tooltip>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-row>
            <v-divider class="mt-4 ml-5"></v-divider>
            <v-btn text color="q_new_b_1" left small v-if="!loading && agent.NPN" @click="addWritingNumber" class="mr-5">
                Add New Writing Number
            </v-btn>
        </v-row>
        <v-dialog v-model="showEditWritingNumber" width="500">
            <v-card class="pa-6 text-center" min-height="">
                <v-card-title>
                    <h4 class="q-display-1 pa-5 white--text text-center q_leads_1" style="min-width:100%;">
                        <span v-if="current_writing_number.ID == null">New Writing Number</span>
                        <span v-else>Edit Writing Number</span>
                    </h4>
                </v-card-title>
                <v-card-text v-if="carrier_list.length > 0">
                    <v-progress-linear indeterminate v-if="updating"></v-progress-linear>
                    <v-select label="Carrier" :items="carrier_list" :disabled="current_writing_number.ID != null" item-text="CarrierName" item-value="ID" v-model="current_writing_number.CarrierID" :value-comparator="checkCarrierID" :rules="[form_rules.required]"></v-select>
                    <v-text-field label="Writing Number" :rules="[form_rules.required]" v-model="current_writing_number.WritingNumber"></v-text-field>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-btn right depressed color="primary" class="mr-4" @click="saveWritingNumber">Save</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    name: "QAgentContracting",
    props: ['agent', 'atype'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            loading: false,
            writing_numbers: [],
            current_writing_number: {},
            showEditWritingNumber: false,
            carrier_list: [],
            updating: false
        }
    },
    mounted: function() {
        this.current_writing_number.NPN = this.atype == "agent" ? this.agent.NPN : this.agent.DoingBusinessAsNPN
        this.getWritingNumbers();
        this.getCarrierList()
    },
    computed: {

    },
    methods: {
        getWritingNumbers() {
            this.loading = true;
            var g = this;
            if (this.atype == "agent") {
                var func = QuilityAPI.getActiveCarrierWritingNumbers(this.agent.AgentCode)
            } else {
                func = QuilityAPI.getActiveAgencyCarrierWritingNumbers(this.agent.AgentCode)
            }
            func.then(function(resp) {
                if (resp.success !== false) {
                    resp.data.sort((a, b) => {
                        let cna = a.CarrierName,
                            cnb = b.CarrierName
                        if (cna < cnb) {
                            return -1;
                        }
                        if (cna > cnb) {
                            return 1;
                        }
                        return 0;
                    })
                    g.$set(g, 'writing_numbers', resp.data)
                } else {
                    g.showError(resp.reason);
                }
                g.loading = false;
            });
        },
        getCarrierList() {
            var g = this;
            QuilityAPI.getCarriers({ itemsPerPage: "all" }).then(function(resp) {
                if (resp.success !== false) {
                    resp.data.sort((a, b) => {
                        let cna = a.CarrierName,
                            cnb = b.CarrierName
                        if (cna < cnb) {
                            return -1;
                        }
                        if (cna > cnb) {
                            return 1;
                        }
                        return 0;
                    });
                    g.$set(g, 'carrier_list', resp.data)
                } else {
                    g.showError(resp.reason);
                }
            });
        },
        handleCopyStatus(status) {
            this.showInfo("Copied to clipboard")
        },
        handleCopyError(status) {
            this.showError("Opps, something went wrong. Select the deisred text and use CTRL+C to copy.")
        },
        addWritingNumber: function() {
            this.current_writing_number = {};
            this.current_writing_number.NPN = this.atype == "agent" ? this.agent.NPN : this.agent.DoingBusinessAsNPN
            this.showEditWritingNumber = true
        },
        editWritingNumber: function(item) {
            this.current_writing_number = item;
            this.showEditWritingNumber = true
        },
        saveWritingNumber: function() {
            //check required fields.
            if (this.current_writing_number.CarrierID == null || this.current_writing_number.WritingNumber == null) {
                return;
            }
            this.updating = true;
            var g = this;
            QuilityAPI.saveCarriertWritingNumber(this.current_writing_number).then(function(resp) {
                if (resp.error !== true) {
                    g.$set(g, 'current_writing_number', resp.data)
                    g.showEditWritingNumber = false
                    g.getWritingNumbers()
                    g.logActivity('SaveWritingNumber', g.current_writing_number)
                } else {
                    g.showError(resp.msg);
                }
                g.updating = false;
            });
        },
        checkCarrierID: function(a, b) {
            //writing_number has ID as a string and the carrier list id has an int.
            return parseInt(a) == parseInt(b)
        },
        deleteWritingNumber: function(item) {
            this.updating = true;
            var g = this;
            QuilityAPI.deleteCarriertWritingNumber(item.ID).then(function(resp) {
                if (resp.success === true) {
                    g.getWritingNumbers()
                    g.showEditWritingNumber = false
                    g.logActivity('DeleteWritingNumber', g.current_writing_number)
                } else {
                    g.showError(resp.msg);
                }
                g.updating = false;
            });
        },
    },
    watch: {
        "agent.AgentCode": function(newV) {
            if (newV != null) {
                this.$nextTick(this.getWritingNumbers)
            }
        }
    },
}

</script>
<style scoped>
</style>
