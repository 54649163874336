import { Ref, ref } from "vue"
import { useThrottleWatch } from "../useThrottleWatch"

export const useGetResource = <Params extends Record<string, any>, Response>(resource: (params: Params) => Promise<Response>, params: Ref<Params>) => {
  const data = ref<Response>()
  const isLoading = ref()

  const refresh = async () => {
    isLoading.value = true
    data.value = await resource(params.value)
    isLoading.value = false
  }

  
  useThrottleWatch(params, refresh, 1000)

  refresh()

  return {
    isLoading,
    data,
    refresh
  }
}