<template>
	<QTabs
		v-model="currentTab"
		dark grow
		height="76" slider-size="10"
		slider-color="#54C1B2" background-color="#125751"
		class="bonus-tables" :class="computedClass" menu-content-class="bonus-tables__menu-content"
	>
		<template v-for="bonus in bonuses">
			<v-tab :key="`${bonus.type}_tab`">{{ bonus.label }}</v-tab>

			<v-tab-item :key="`${bonus.type}_tab-item`">
				<v-row v-if="hasBonusData.availableBonuses?.[bonus.type] === false" justify="center">
					<v-col cols="auto">
						<div
							v-html="bonusDefinitions[bonus.type]"
							class="bonus-tables__bonus-definition"
						/>
					</v-col>
				</v-row>

				<template v-else-if="hasBonusData.availableBonuses?.[bonus.type] === true">
					<BirdDogBonusItem
						v-if="bonus.type === bonusTypes.birdDog"
						:bonus-type="bonus.type"
						:agent="agent"
						@submit-dispute="submitForm"
					/>
					<BonusItem
						v-else
						:bonus-type="bonus.type"
						:agent="agent"
						@submit-dispute="submitForm"
					/>
				</template>

				<v-progress-linear v-else class="mt-1" indeterminate />
			</v-tab-item>
		</template>

		<v-dialog
			v-if="doShowForm"
			v-model="doShowForm"
			:fullscreen="$vuetify.breakpoint.xs"
			width="100%"
			max-width="800px"
			@click:outside="closeAndClearForm"
			@keydown.esc="closeAndClearForm"
		>
			<v-card>
				<v-card-title>Dispute Form Data</v-card-title>
				<FormRenderer
					:slug="formSlug"
					class="px-4 pb-4"
					@saved="onFormSubmit"
				/>
			</v-card>
		</v-dialog>
	</QTabs>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI'
import Page from '@/store/Models/Page'
import QTabs from '@/components/navigation/QTabs.vue'
import FormRenderer from '@/modules/FML/components/FormRenderer.vue'
import BonusItem from './BonusItem.vue'
import BirdDogBonusItem from './BirdDogBonusItem.vue'
import { bonuses, bonusTypes } from '../config/bonusConfigs'

export default {
    components: {
		QTabs,
		BonusItem,
		BirdDogBonusItem,
		FormRenderer,
	},
	props: {
		value: {
			type: String,
			default: '',
		},
		hasBonusData: {
			type: Object,
			default: () => ({}),
		},
		agent: { type: Object },
	},
    data () {
        return {
			bonuses,
			bonusTypes,

			cmsPageSlug: 'bonus-report-definitions',
			cmsPageLoading: false,
			cmsPageError: false,
			cmsPageBlocks: null,

			formSlug: undefined,
			doShowForm: false,
        }
    },
	created () {
		this.loadCmsPage()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.mdAndDown && !this.isMobileView
		},
		isMobileTableView () {
			return this.isMobileView || this.isTabletView
		},
		computedClass () {
			return [
				this.isMobileView && 'bonus-tables--mobile',
				this.isTabletView && 'bonus-tables--tablet',
				!this.hasBonusData.availableBonuses && 'bonus-tables--progress',
			]
		},
		currentTab: {
			get () {
				return this.bonuses.findIndex(({ type }) => type === this.value)
			},
			set (val) {
				const type = this.bonuses[val].type
				this.$emit('input', type)
			},
		},
		bonusDefinitions () {
			if (!this.cmsPageBlocks) { return {} }
			return this.bonuses.reduce((acc, { type, label }) => {
				acc[type] = this.cmsPageBlocks.find(({ Title }) => {
					const title = Title.trim?.().toLowerCase()
					return title === type.toLowerCase() || title === label.toLowerCase()
				})?.text
				return acc
			}, {})
		},
    },
	watch: {
		currentTab () {
			this.closeAndClearForm()
		},
	},
	methods: {
		async loadCmsPage () {
			const page = Page.query().where('slug', this.cmsPageSlug).first()
			if (page) {
				this.cmsPageBlocks = page.blocks
				return Promise.resolve()
			}
			this.cmsPageLoading = true
			this.cmsPageError = false
			try {
				await QuilityAPI.getPageWithSlug(this.cmsPageSlug)
				const page = Page.query().where('slug', this.cmsPageSlug).first()
				this.cmsPageBlocks = page.blocks
			} catch (err) {
				this.showError('Oops! There was a problem loading the cms page with bonus definitions.', err.message)
				this.cmsPageError = true
			} finally {
				this.cmsPageLoading = false
			}
		},
		submitForm (bonusType) {
			this.formSlug = this.getFormSlug(bonusType)
			this.doShowForm = true
		},
		onFormSubmit () {
			this.showSuccess('Dispute Form submitted.')
			this.closeAndClearForm()
		},
		closeAndClearForm () {
			this.doShowForm = false
			this.formSlug = undefined
		},
		getFormSlug (bonusType) {
			switch (bonusType) {
				case bonusTypes.sherpa:
					return 'sherpa-bonus-dispute-form'
				case bonusTypes.birdDog:
					return 'bird-dog-bonus-dispute-form'
				case bonusTypes.pcs:
					return 'commission-dispute-form'
				case bonusTypes.qrsFif:
					return 'commission-dispute-form'
				default:
					return 'bonus-dispute-form'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: bonus-tables;

.#{$block} {
	--font-tab: 1.5rem;

	&--tablet {
		--font-tab: 1.5rem;
	}

	&--mobile {
		--font-tab: 1.5rem;
	}

	&:not(.bonus-tables--progress) {
		::v-deep .v-tabs-items {
			padding: 0;
			background-color: $color-white;
			border: 1px solid $color-card-border;
			border-top: none;
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}

	margin-top: 3rem;

	::v-deep .q-tabs__select {
		.v-select__selection {
			margin: 0 0.5rem 0 0;
			font-size: var(--font-tab);
			line-height: 1;
		}
	}

	::v-deep .v-tabs {
		.v-tab {
			font-size: var(--font-tab);
			color: $color-white !important;
			text-transform: unset;
		}
		.v-tab--active {
			background-color: $color-tabs-active;
		}
	}

	&__bonus-definition {
		padding-top: 1rem;
		max-width: 1200px;
		overflow: hidden;
	}
}
</style>

<style lang="scss">
@import '../style/style.scss';

.bonus-tables__menu-content {
	.v-list {
		background-color: $color-tabs !important;

		.v-list-item--active {
			color: inherit !important;
		}
	}
}
</style>
