import moment from 'moment'

import tableConfigs, { bonusTypes, bonuses } from '../config/bonusConfigs'

export default {
    props: {
        bonusType: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
		items: {
			type: Array,
			default: () => [],
		},
		totalBonus: {
			type: Number,
			required: true,
		},
		options: {
			type: Object,
			required: true,
		},
		filters: {
			type: Object,
			required: true,
		},
		serverItemsLength: {
			type: Number,
			default: -1,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
		expandedTable: {
			type: Boolean,
			default: false,
		},
    },
    data () {
        return {
            expanded: [],
			tableKey: 0,
			itemUniqueKey: 'ID',
			disclaimer: 'All bonus programs are based on net placed and are discretionary and may be amended, modified or terminated, in whole or in part, at any time at Symmetry Financial Group’s sole discretion. All bonuses and bonus payments are contingent on the recipient being in Good Standing (as defined in the Agent Handbook) when the bonus is earned and on the date the payment is to be made to receive the bonus.',
        }
    },
    computed: {
		computedSearch: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		computedOptions: {
			get () { return this.options },
			set (v) { this.$emit('update-options', v) },
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		isMobileTableView () {
			return this.isMobileView || this.isTabletView
		},
		computedClass () {
			return [
				this.isMobileView && 'bonus-table--mobile',
				this.isTabletView && 'bonus-table--tablet',
			]
		},
		doShowDisclaimer () {
			return this.bonusType !== bonusTypes.pcs && this.bonusType !== bonusTypes.qrsFif
		},
		title () {
			return bonuses.find(({ type }) => type === this.bonusType)?.label
		},
		config () {
			return tableConfigs[this.bonusType] || []
		},
		headers () {
			return this.config
				.filter(this.filterTableHeaders)
				.map(item => ({
					text: item.label,
					value: item.key,
					class: this.getColumnClass(item),
					cellClass: this.getColumnClass(item),
				}))
		},
		visibleHeaders () {
			return this.isMobileTableView
				? this.headers.filter(({ value }) => this.config.find(({ key }) => key === value).mobileVisible)
				: this.headers.filter(({ value }) => this.config.find(({ key }) => key === value).visible !== false)
		},
		expandedHeaders () {
			return this.headers.filter(({ value }) => !this.visibleHeaders.some(h => h.value === value))
		},
		showExpand () {
			return this.expandedHeaders.length !== 0
		},
		formattedDatePeriod () {
			const { startDate, endDate } = this.filters
			if (!startDate && !endDate) { return 'Entire period (all data)' }
			return `${moment(startDate).format('MM/DD/YY')} - ${moment(endDate).format('MM/DD/YY')}`
		},
    },
    watch: {
		isMobileTableView () {
			this.tableKey += 1
		},
		items () {
			this.expanded = []
		},
    },
    methods: {
		maybeSearch (event) {
            if (event.keyCode !== 13) { return }
            // if (!this.computedSearch || this.computedSearch.length < 3) { return }
			this.$emit('search')
        },
		formatItem (value, headerKey) {
			const config = this.config.find(({ key }) => key === headerKey)
			const valueSign = value < 0 ? '-' : ''
			if (config.type === 'currency') {
				const format = config.rounded ? '($0,0)' : '($0,0[.]00)'
				return valueSign + this.formatNumber(Math.abs(value), format)
			}
			if (config.type === 'percent') {
				const format = config.rounded ? '(0)' : '(0[.]00)'
				return valueSign + this.formatNumber(Math.abs(value), format) + '%'
			}
			if (config.type === 'date') {
				if (!value) { return '-' }
				return (config.format || config.toFormat)
					? moment(value, config.format).format(config.toFormat)
					: value
			}
			if (value === undefined || value === null) {
				return '-'
			}
			return value
		},
		getColumnClass (item) {
			return ['currency', 'percent'].includes(item.type) ? 'text-end' : ''
		},
		filterTableHeaders () { // can be implemented in component
			return true
		},
    },
}
