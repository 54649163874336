import { ref } from "vue"

const clickedAgent = ref('')

export const useClickedAgent = () => {
  const setClickedAgent = (agent: string) => {
    if (clickedAgent.value === agent) {
      clickedAgent.value = ''
      return
    }

    clickedAgent.value = agent
  }

  return {
    clickedAgent,
    setClickedAgent
  }
}