<template>
    <v-container fluid class="grey lighten-5 pa-0">
        <QAgencyBuildingSubmenu />

        <v-row justify="space-between" align="stretch" class="mx-5" id="EverPlans">
            <v-col cols="12">
                <h2>My Reset Agent Pool</h2>
                <v-row>
                    <v-col cols="12" md="4">
						<v-select label="Reset Type" v-model="pool" :items="poolOptions" :messages="resetTypeDefinition" />
					</v-col>
                    <v-col cols="12" md="8" lg="6">
						<QAgentCalendarLinkForm
							ref="agentCalendarLinkForm"
							:agent-calendar-links="agentCalendarLinks"
							:pool="pool"
							:filters="filters"
							:calendar-agent-code="agent.AgentCode"
							:calendar-url-key="calendarUrlKey"
							:loading="dataSaving"
							:disabled="!isMyPoolView"
							@add-link="addCalendarLink"
						/>
					</v-col>
					<v-col cols="12" md="auto">
						<QButton
							class="mt-3"
							:disabled="isFindAgentsDialogButtonDisabled"
							@click="showFindAgentsDialog"
						>
							Find Certified Agents
						</QButton>
					</v-col>
                    <v-col cols="12">
						<QAgentCalendarLinkTable
							v-model="doShowEditDialog"
							:agent-calendar-links="calendarPoolData"
							:certified-agents="certifiedAgentsCache[cacheKey]"
							:title="`${pool} Calendar Links`"
							:pool="pool"
							:search="calendarLinkTableSearch"
							:options="calendarLinkTableOptions"
							:server-items-length="calendarLinkTableServerItemsLength"
							:is-my-pool-view="isMyPoolView"
							:calendar-url-key="calendarUrlKey"
							:loading="dataSaving"
							@search="searchData"
							@update-search="calendarLinkTableSearch = $event"
							@update-options="calendarLinkTableOptions = $event"
							@refresh-links="loadPool"
							@remove-link="removeCalendarLink"
							@update-link="updateCalendarLink"
						>
							<template #before>
								<div v-if="allowDownlinePools || allowExportResults" class="calendar-links-actions">
									<QLabeledSwitch
										v-if="allowDownlinePools"
										v-model="poolView"
										hide-details dense inset
										color="#360568"
										:label-before="MY_POOL"
										:label-after="DOWNLINE_POOLS"
									/>

									<v-btn
										v-if="allowExportResults"
										text
										color="#360568"
										class="ml-auto my-1"
										:disabled="disabledExportResults"
										@click="exportResults"
									>
										<v-icon>fas fa-file-export</v-icon>
										<v-divider class="mx-2" vertical />
										Export Results
									</v-btn>
								</div>
							</template>
						</QAgentCalendarLinkTable>
					</v-col>
                </v-row>
            </v-col>
        </v-row>

		<v-dialog
			v-model="doShowFindAgentsDialog"
			:fullscreen="$vuetify.breakpoint.xs"
			width="100%"
			max-width="1600px"
		>
			<QFindCertifiedAgents
				v-if="doShowFindAgentsDialog"
				v-model="certifiedAgentsFilters"
				:pool="pool"
				:agent-calendar-links="agentCalendarLinks"
				:certified-agents="certifiedAgentsCache[cacheKey]"
				:calendar-url-key="calendarUrlKey"
				:saving="dataSaving"
				@close="doShowFindAgentsDialog = false"
				@add-link="addCalendarLink"
				@remove-link="removeCalendarLink"
				@update-link="updateCalendarLink"
			/>
		</v-dialog>
    </v-container>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI.js'
import QAgentCalendarLinkForm from '@/components/form/QAgentCalendarLinkForm.vue'
import QAgentCalendarLinkTable from '@/components/datatables/QAgentCalendarLinkTable.vue'
import QFindCertifiedAgents from '@/components/datatables/QFindCertifiedAgents.vue'
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue'
import QLabeledSwitch from '@/components/utils/QLabeledSwitch.vue'
import QButton from '@/modules/shared/components/QButton.vue'
import ExportToCSVMixin from '@/mixins/ExportToCSVMixin'

const resetTypeDefinitions = {
	'Debt Free Life': 'DFL agents have completed the DFL certification course in QuilityU',
	IUL: 'IUL agents have completed the IUL certification course in QuilityU',
	Annuity: 'Annuity agents have completed the Annuity certification course in QuilityU and have completed an in person bootcamp',
	IBC: 'IBC agents have completed the IBC certification course in QuilityU',
}

const resetPoolMap = {
	IUL: 'IUL',
	Annuity: 'Annuity',
	IBC: 'IBC',
	'Debt Free Life': 'DFL',
}

const calendarUrlKeyMap = {
	IUL: 'IULCalendarUrl',
	Annuity: 'AnnuityCalendarUrl',
	IBC: 'DFLCalendarUrl',
	'Debt Free Life': 'DFLCalendarUrl',
}

const filtersMap = {
	IUL: { Status : 'Active,Available', IULCertifiedInd : 1 },
	Annuity: { Status : 'Active,Available', AnnuitySME : 1 },
	IBC: { Status : 'Active,Available', IBCCertifiedInd : 1 },
	'Debt Free Life': { Status : 'Active,Available', DFLCertifiedInd : 1 },
}

const sortByMap = {
	'Agent.AgentName': 'AgentName',
	'ConfigAgent': 'ConfigAgentAgentName',
	'CalendarURL': 'CalendarURL',
}

const MY_POOL = 'My Pool'
const DOWNLINE_POOLS = 'Downline Pools'

const defaultOptions = {
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	sortDesc: [],
}

export default {
    name: 'AgentCalendarLinks',
	mixins: [ExportToCSVMixin],
    components: {
        QAgentCalendarLinkForm,
        QAgentCalendarLinkTable,
        QAgencyBuildingSubmenu,
		QButton,
		QFindCertifiedAgents,
		QLabeledSwitch,
    },
    data () {
        return {
			MY_POOL,
			DOWNLINE_POOLS,
            pool: 'Debt Free Life',
			poolView: MY_POOL,
			agentCalendarLinks: {
				loading: false,
				error: false,
				items: null,
			},
			downlinePools: {
				loading: false,
				error: false,
				items: null,
			},
			dataSaving: false,
			doShowFindAgentsDialog: false,
			certifiedAgentsCache: {},
			doShowEditDialog: false,
			certifiedAgentsFilters: {
				statType: 'Baseshop',
			},
			calendarLinkTableSearch: '',
			calendarLinkTableServerItemsLength: undefined,
			calendarLinkTableOptions: { ...defaultOptions },
        }
    },
	created () {
		this.setNewCertifiedAgentsCache()
	},
	mounted () {
		this.loadPool()
		this.loadCertifiedAgents()
	},
    computed: {
        agent () {
            return this.user.Agent
        },
		poolOptions () {
			return [
				'Debt Free Life',
				'IUL',
				'IBC',
				this.hasRole(['SuperAdmin', 'AgencyOwner']) && 'Annuity',
			].filter(Boolean)
		},
		resetTypeDefinition () {
			return resetTypeDefinitions[this.pool]
		},
        filters () {
			return { ...filtersMap[this.pool], attr: this.calendarUrlKey }
        },
		calendarUrlKey () {
			return calendarUrlKeyMap[this.pool]
		},
        isFindAgentsDialogButtonDisabled () {
            return !this.isMyPoolView ||
				this.doShowFindAgentsDialog ||
				this.agentCalendarLinks.loading || this.agentCalendarLinks.error ||
				this.dataSaving
        },
		cacheKey () {
			const filtersKey = Object.values(this.certifiedAgentsFilters)
				.map(item => item.replaceAll(' ', ''))
				.sort()
				.join('_')
			return `${resetPoolMap[this.pool]}_${filtersKey}`
		},
		isMyPoolView () {
			return this.poolView === MY_POOL
		},
		calendarPoolData () {
			return this.isMyPoolView ? this.agentCalendarLinks : this.downlinePools
		},
		allowDownlinePools () {
			return !this.hasRole(['SuperAdmin', 'Staff'])
		},
		allowExportResults () {
			if (this.hasRole(['SuperAdmin', 'Staff'])) { return true }
			return false
		},
		disabledExportResults () {
			return this.calendarPoolData.loading ||
				this.calendarPoolData.error ||
				!this.calendarPoolData.items?.length
		},
    },
    watch: {
        pool () {
			this.setNewCertifiedAgentsCache()
			this.agentCalendarLinks.items = null
			this.downlinePools.items = null
			this.resetCalendarLinkTableOptions()
            this.loadPool()
			if (this.isMyPoolView) {
				this.loadCertifiedAgents()
			}
        },
        isMyPoolView () {
			if (!this.isMyPoolView) {
				this.$refs.agentCalendarLinkForm?.clearSearch()
			}
			this.agentCalendarLinks.items = null
			this.downlinePools.items = null
			this.resetCalendarLinkTableOptions()
            this.loadPool()
			if (this.isMyPoolView) {
				this.loadCertifiedAgents()
			}
        },
        certifiedAgentsFilters: {
			deep: true,
			handler () {
				this.setNewCertifiedAgentsCache()
				this.loadCertifiedAgents()
			}
        },
        'agent.AgentCode' () {
			this.certifiedAgentsCache = {}
			this.setNewCertifiedAgentsCache()
			this.agentCalendarLinks.items = null
			this.downlinePools.items = null
			this.resetCalendarLinkTableOptions()
            this.loadPool()
			this.loadCertifiedAgents()
        },
		calendarLinkTableOptions: {
			deep: true,
			handler () {
				if (this.isMyPoolView) { return }
				this.loadDownlineData()
			},
		},
    },
    methods: {
		updateDataState (loading, error, links) {
			this.agentCalendarLinks = { loading, error, items: links ? [...links] : null }
		},
		updateDownlineDataState (loading, error, links) {
			this.downlinePools = { loading, error, items: links ? [...links] : null }
		},
		loadPool () {
			if (this.isMyPoolView) {
				this.loadData()
			} else {
				this.loadDownlineData()
			}
		},
		searchData () {
			if (this.isMyPoolView) { return }
			this.calendarLinkTableOptions.page = 1
			this.loadDownlineData()
		},
		async loadData () {
			this.updateDataState(true, false, this.agentCalendarLinks.items)
			try {
				const res = await QuilityAPI.getAgentConfigsAppointmentCalendar(this.agent.AgentCode, resetPoolMap[this.pool])
				this.updateDataState(false, false, res.data)
			} catch (err) {
				if (err.message === 'Aborted') { return }
				console.error('Error loading calendar links.', err)
				this.showError('Error loading calendar links.<br>', err.message)
				this.updateDataState(false, true)
			}
        },
		loadDownlineData: debounce(async function () {
			this.updateDownlineDataState(true, false, this.downlinePools.items)
			const sort_direction = typeof this.calendarLinkTableOptions.sortDesc[0] === 'boolean'
				? this.calendarLinkTableOptions.sortDesc[0] ? 'desc' : 'asc'
				: undefined
			const params = {
				page: this.calendarLinkTableOptions.page,
				itemsPerPage: this.calendarLinkTableOptions.itemsPerPage,
				sort_field: sortByMap[this.calendarLinkTableOptions.sortBy],
				sort_direction,
				search: this.calendarLinkTableSearch,
				reset_pool: resetPoolMap[this.pool],
			}
			try {
				const res = await QuilityAPI.getDownlineCalendarConfig(this.agent.AgentCode, params)
				const data = this.formatDownlineData(res.data)
				this.calendarLinkTableServerItemsLength = res.meta?.total
				this.updateDownlineDataState(false, false, data)
			} catch (err) {
				if (err.message === 'Aborted') { return }
				console.error('Error loading Downline Pools.', err)
				this.showError('Error loading Downline Pools.<br>', err.message)
				this.updateDownlineDataState(false, true)
			}
        }, 200),
        async addCalendarLink (calendarURL, agent) {
			if (!this.isMyPoolView) { return }
			this.dataSaving = true
            const calendarData = {
                'AgentCode': agent.AgentCode,
                'CalendarURL': calendarURL,
                'Approved': false,
                'ApprovedBy': null,
                'reset_pool' : resetPoolMap[this.pool]
            }
			try {
				const res = await QuilityAPI.addAgentConfigAppointmentCalendar(this.agent.AgentCode, calendarData)
				if (!res.success) { throw new Error('Failed to add link (server response unsuccessful).') }
				await this.loadData()
				this.showAddingConfirmation(agent, calendarURL, resetPoolMap[this.pool])
			} catch (err) {
				console.error('Error adding calendar link', err)
				this.showError('Error adding calendar link.<br>', err.message)
			} finally {
				this.dataSaving = false
			}
        },
        removeCalendarLink (item, calendarURL) {
			if (!this.isMyPoolView) { return }
			this.dataSaving = true
			const agent = this.agentCalendarLinks.items.find(({ Agent }) => Agent.AgentCode === item.AgentCode)
            const cfg_agent = agent.ConfigAgent ? agent.ConfigAgent.AgentCode : this.agent.AgentCode
			const agentCalendarURL = calendarURL || agent.CalendarURL
            QuilityAPI.deleteAgentConfigAppointmentCalendar(cfg_agent, { agent_code: agent.Agent.AgentCode, reset_pool : resetPoolMap[this.pool], calendar_url: agentCalendarURL })
                .then((json) => {
					this.updateDataState(false, false, json.data)
                    this.showRemovingConfirmation(item, agentCalendarURL, resetPoolMap[this.pool])
                })
                .catch((err) => {
                    console.error('Error removing calendar link.', err)
                    this.showError('Error removing calendar link.<br>', err.message)
                })
				.finally(() => { this.dataSaving = false })
        },
        updateCalendarLink (calendarURL, item) {
			if (!this.isMyPoolView) { return }
            this.dataSaving = true
			const agent = this.agentCalendarLinks.items.find(({ Agent }) => Agent.AgentCode === item.AgentCode)
            const cfg_agent = agent.ConfigAgent ? agent.ConfigAgent.AgentCode : this.user.Agent.AgentCode
            const calendarData = {
                'AgentCode': agent.Agent.AgentCode,
                'CalendarURL': calendarURL,
                'Approved': false,
                'ApprovedBy': null,
                'reset_pool' : resetPoolMap[this.pool],
            }
            QuilityAPI.updateAgentConfigAppointmentCalendar(cfg_agent, calendarData)
                .then((json) => {
					this.updateDataState(false, false, json.data)
					this.doShowEditDialog = false
                    this.showUpdatingConfirmation(item, calendarURL, resetPoolMap[this.pool])
                })
                .catch((err) => {
                    console.error('Error updating calendar link.', err)
                    this.showError('Error updating calendar link.<br>', err.message)
                })
                .finally(() => { this.dataSaving = false })
        },
		async loadCertifiedAgents () {
            const cacheKey = this.cacheKey  // this is necessary because the computed value may change during an asynchronous request
			if (this.certifiedAgentsCache[cacheKey].items || this.certifiedAgentsCache[cacheKey].loading) {
				return Promise.resolve()
			}
			this.certifiedAgentsCache[cacheKey].loading = true
			this.certifiedAgentsCache[cacheKey].error = false
			try {
				const res = await QuilityAPI.getSymmetryAgentSearch({ ...this.filters, ...this.certifiedAgentsFilters, itemsPerPage: 'all' })
				this.certifiedAgentsCache[cacheKey].items = res.data
				this.certifiedAgentsCache[cacheKey].loaded = true
				this.certifiedAgentsCache[cacheKey].loading = false
			} catch (err) {
				if (err.message === 'Aborted') { return }
				console.error('Issue with loading certified agents data', err)
				this.showError('Issue with loading certified agents data.<br>', err.message)
				this.certifiedAgentsCache[cacheKey].loading = false
				this.certifiedAgentsCache[cacheKey].error = true
			}
		},
		showAddingConfirmation (agent, calendarURL, resetPool) {
			this.showSuccess(`Agent <strong>${agent.AgentName}</strong>'s (${agent.AgentCode}) calendar URL <strong>${calendarURL}</strong> has been added to your <strong>${resetPool}</strong> reset pool.`)
		},
		showRemovingConfirmation (agent, calendarURL, resetPool) {
			this.showSuccess(`Agent <strong>${agent.AgentName}</strong>'s (${agent.AgentCode}) calendar URL <strong>${calendarURL}</strong> has been removed from your <strong>${resetPool}</strong> reset pool.`)
		},
		showUpdatingConfirmation (agent, calendarURL, resetPool) {
			this.showSuccess(`Agent <strong>${agent.AgentName}</strong>'s (${agent.AgentCode}) calendar URL has been updated in your <strong>${resetPool}</strong> reset pool. New calendar URL is <strong>${calendarURL}</strong>.`)
		},
		showFindAgentsDialog () {
			if (!this.isMyPoolView) { return }
            this.certifiedAgentsFilters = {
                statType: 'Baseshop',
            }
			this.doShowFindAgentsDialog = true
		},
		setNewCertifiedAgentsCache () {
			if (this.certifiedAgentsCache[this.cacheKey]) { return }
			this.$set(this.certifiedAgentsCache, this.cacheKey, { loading: false, loaded: false, error: false, items: null })
		},
		formatDownlineData (rawData) {
			if (!rawData?.length) { return [] }
			return rawData.map((data) => {
				const { AgentCode, AgentName, ConfigAgentAgentID, ConfigAgentAgentName, ConfigAgentAgentCode, ...rest } = data
				return {
					Agent: { AgentCode, AgentName },
					ConfigAgent: {
						AgentCode: ConfigAgentAgentCode,
						AgentName: ConfigAgentAgentName,
						AgentID: ConfigAgentAgentID,
					},
					...rest,
				}
			})
		},
		resetCalendarLinkTableOptions () {
			const itemsPerPage = this.calendarLinkTableOptions.itemsPerPage
			this.calendarLinkTableOptions = { ...defaultOptions, itemsPerPage }
			this.calendarLinkTableServerItemsLength = undefined
		},
		exportResults () {
			if (!this.allowExportResults) { return }
			const fields = ['Agent.AgentName', 'ConfigAgent.AgentName', 'CalendarURL']
			const data = this.calendarPoolData.items
			const poolTitle = this.isMyPoolView ? 'MyResetAgentPool' : 'DownlinePools'
			const title = `${poolTitle}_${resetPoolMap[this.pool]}`
			this.exportToCSV(fields, data, title)
		},
    },
}
</script>

<style scoped lang="scss">
.calendar-links-actions {
	margin: 0 0.5rem 1rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem 2.5rem;
}

::v-deep .v-messages__message {
	line-height: 1.3;
}
</style>
