<template>
	<div v-if="isMobileTableView" class="breakdown-table mt-1">
		<div v-if="referringItem" class="breakdown-table__table-wrapper">
			<v-simple-table class="breakdown-table__mobile-table">
				<tbody>
					<tr v-for="header in referringHeaders" :key="header.value">
						<td class="text-start cell-title">
							{{ header.text }}
						</td>
						<td class="text-end">
							<template v-if="header.value === 'AgentName'">
								<router-link v-if="referringItem.AgentCode" :to="`/agents/${referringItem.AgentCode}`" @click.native.stop class="bonus-item__table-link-btn">{{ referringItem.AgentName || 'go to agent details' }}</router-link>
								<span v-else>{{ referringItem.AgentName || 'NA' }}</span>
							</template>
							<template v-else-if="header.value === 'BirdDog_BonusRecipientAgentName'">
								<router-link v-if="referringItem.BirdDog_BonusRecipientAgentCode" :to="`/agents/${referringItem.BirdDog_BonusRecipientAgentCode}`" @click.native.stop class="bonus-item__table-link-btn">{{ referringItem.BirdDog_BonusRecipientAgentName || 'go to agent details' }}</router-link>
								<span v-else>{{ referringItem.BirdDog_BonusRecipientAgentName || 'NA' }}</span>
							</template>
							<template v-else>
								{{ formatItem(referringItem[header.value], header.value) }}
							</template>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
		</div>

		<div v-if="uplineItems.length" class="breakdown-table__table-wrapper mt-1">
			<v-simple-table v-for="(uplineItem, idx) in uplineItems" :key="idx" class="breakdown-table__mobile-table">
				<tbody>
					<tr v-for="header in uplineHeaders" :key="header.value">
						<td class="text-start cell-title">
							{{ header.text }}
						</td>
						<td class="text-end">
							<template v-if="header.value === 'AgentName'">
								<router-link v-if="uplineItem.AgentCode" :to="`/agents/${uplineItem.AgentCode}`" @click.native.stop class="bonus-item__table-link-btn">{{ uplineItem.AgentName || 'go to agent details' }}</router-link>
								<span v-else>{{ uplineItem.AgentName || 'NA' }}</span>
							</template>
							<template v-else-if="header.value === 'BirdDog_BonusRecipientAgentName'">
								<router-link v-if="uplineItem.BirdDog_BonusRecipientAgentCode" :to="`/agents/${uplineItem.BirdDog_BonusRecipientAgentCode}`" @click.native.stop class="bonus-item__table-link-btn">{{ uplineItem.BirdDog_BonusRecipientAgentName || 'go to agent details' }}</router-link>
								<span v-else>{{ uplineItem.BirdDog_BonusRecipientAgentName || 'NA' }}</span>
							</template>
							<template v-else>
								{{ formatItem(uplineItem[header.value], header.value) }}
							</template>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
		</div>
	</div>

	<div v-else class="breakdown-table breakdown-table__table-wrapper">
		<v-simple-table v-if="referringItem" dense class="breakdown-table__line-table">
			<thead>
				<tr>
					<th v-for="header in referringHeaders" :key="header.value">
						<span>{{ header.text }}</span>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td v-for="header in referringHeaders" :key="header.value">
						<span class="nowrap">
							<template v-if="header.value === 'AgentName'">
								<router-link v-if="referringItem.AgentCode" :to="`/agents/${referringItem.AgentCode}`" @click.native.stop class="bonus-item__table-link-btn">{{ referringItem.AgentName || 'go to agent details' }}</router-link>
								<span v-else>{{ referringItem.AgentName || 'NA' }}</span>
							</template>
							<template v-else-if="header.value === 'BirdDog_BonusRecipientAgentName'">
								<router-link v-if="referringItem.BirdDog_BonusRecipientAgentCode" :to="`/agents/${referringItem.BirdDog_BonusRecipientAgentCode}`" @click.native.stop class="bonus-item__table-link-btn">{{ referringItem.BirdDog_BonusRecipientAgentName || 'go to agent details' }}</router-link>
								<span v-else>{{ referringItem.BirdDog_BonusRecipientAgentName || 'NA' }}</span>
							</template>
							<template v-else>
								{{ formatItem(referringItem[header.value], header.value) }}
							</template>
						</span>
					</td>
				</tr>
			</tbody>
		</v-simple-table>

		<v-simple-table v-if="uplineItems.length" dense class="breakdown-table__line-table mt-4">
			<thead>
				<tr>
					<th v-for="header in uplineHeaders" :key="header.value">
						<span>{{ header.text }}</span>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(uplineItem, idx) in uplineItems" :key="idx">
					<td v-for="header in uplineHeaders" :key="header.value">
						<span class="nowrap">
							<template v-if="header.value === 'AgentName'">
								<router-link v-if="uplineItem.AgentCode" :to="`/agents/${uplineItem.AgentCode}`" @click.native.stop class="bonus-item__table-link-btn">{{ uplineItem.AgentName || 'go to agent details' }}</router-link>
								<span v-else>{{ uplineItem.AgentName || 'NA' }}</span>
							</template>
							<template v-else-if="header.value === 'BirdDog_BonusRecipientAgentName'">
								<router-link v-if="uplineItem.BirdDog_BonusRecipientAgentCode" :to="`/agents/${uplineItem.BirdDog_BonusRecipientAgentCode}`" @click.native.stop class="bonus-item__table-link-btn">{{ uplineItem.BirdDog_BonusRecipientAgentName || 'go to agent details' }}</router-link>
								<span v-else>{{ uplineItem.BirdDog_BonusRecipientAgentName || 'NA' }}</span>
							</template>
							<template v-else>
								{{ formatItem(uplineItem[header.value], header.value) }}
							</template>
						</span>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
	</div>
</template>

<script>
import Level from '@/store/Models/AgentManagement/Level'

const config = [
	{ key: 'BirdDog_Pct', type: 'percent' },
	{ key: 'BirdDog_UplinePct', type: 'percent' },
	{ key: 'PaymentAmount', type: 'currency' },
]

const leadershipLevelsDefaults = [
    "Managing Partner",
    "Senior Partner",
    "Associate Partner",
    "Executive Vice President",
    "Senior Vice President",
    "Managing Vice President",
    "Regional Agency Director",
    "Agency Director",
    "Agency Owner",
    "Key Leader",
    "Team Leader",
    "Sales Representative",
    "SFG",
    "Asurea",
    "Wholesale Agency",
    "Tenant",
    "Quility",
]

export default {
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
		isMobileTableView: {
			type: Boolean,
			default: false,
		},
		hasAdminPermissions: {
			type: Boolean,
			default: false,
		},
    },
    computed: {
		leadershipLevels () {
			const levels = Level.query().where('LevelType', 'Leadership').get()
			if (!levels.length) { return leadershipLevelsDefaults }
			return levels
				.sort((a, b) => b.LevelRank - a.LevelRank)
				.map(({ LevelName }) => LevelName)
		},
		referringHeaders () {
			return [
				{ text: 'Referring Agent', value: 'AgentName' },
				{ text: 'Leadership Level', value: 'LeadershipLevel' },
				{ text: 'BDOG %', value: 'BirdDog_Pct' },
				{ text: 'BDOG $', value: 'PaymentAmount' },
				{ text: 'Bonus Recipient', value: 'BirdDog_BonusRecipientAgentName' },
			]
		},
		uplineHeaders () {
			return [
				{ text: 'Upline Agent', value: 'AgentName' },
				{ text: 'Leadership Level', value: 'LeadershipLevel' },
				{ text: 'Upline %', value: 'BirdDog_UplinePct' },
				{ text: 'Upline $', value: 'PaymentAmount' },
				{ text: 'Bonus Recipient', value: 'BirdDog_BonusRecipientAgentName' },
			]
		},
		referringItem () {
			const items = this.item._details.data || []
			return items.find(({ BirdDog_ParentBonusID }) => BirdDog_ParentBonusID === null)
		},
		uplineItems () {
			const items = this.item._details.data || []
			const uplines = items.filter(({ BirdDog_ParentBonusID }) => BirdDog_ParentBonusID !== null)
			return this.sortByLeadershipLevelsOrder(uplines)
		},
    },
    methods: {
		formatItem (value, headerKey) {
			const item = config.find(({ key }) => key === headerKey)
			const valueSign = value < 0 ? '-' : ''
			if (item?.type === 'currency') {
				const format = item.rounded ? '($0,0)' : '($0,0[.]00)'
				return valueSign + this.formatNumber(Math.abs(value), format)
			}
			if (item?.type === 'percent') {
				const format = item.rounded ? '(0)' : '(0[.]00)'
				return valueSign + this.formatNumber(Math.abs(value), format) + '%'
			}
			if (value === undefined || value === null) {
				return '-'
			}
			return value
		},
		sortByLeadershipLevelsOrder (items) {
			const base = [...items]
			const sorted = []
			this.leadershipLevels.forEach((level) => {
				const items = base.filter(item => item.LeadershipLevel === level)
				sorted.push(...items)
			})
			const unsorted = base.filter(item => !sorted.some(({ ID }) => ID === item.ID ))			
			sorted.push(...unsorted)
			return sorted
		},
    },
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: breakdown-table;

.#{$block} {
	background-color: $color-white;

	&__table-wrapper {
		padding: 0.5rem;
		border-radius: 0.25rem;
		color: #00000099;
		box-shadow: 0px 0px 1px 1px $color-card-border;
	}

	&__mobile-table {
		&:not(:first-child) {
			margin-top: 2rem;
		}

		table {
			td {
				height: 30px !important;
				line-height: 1.3 !important;
			}
	   	}
	}

	&__line-table {
		table {
			th {
				background-color: $color-bg;
			}
			th, td {
				height: 24px !important;
				line-height: 1.3 !important;
				vertical-align: middle !important;
			}
	   	}
	}

	::v-deep .cell-title {
		font-weight: 600;
	}
}
</style>
