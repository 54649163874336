<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 grey lighten-2" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Agents
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="AgentCode" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" single-expand show-expand :expanded.sync="expanded">
                <template v-slot:item.AgentName="{ item }">
                    {{item.AgentName}}
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-if="item.HasDFLSMEInDownline == true"> <v-icon v-on="on" color="black">icon-q-dfl-icon-circle</v-icon></span>
                        </template>
                        <span>This agent has a DFL SME in their downline.</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.AgentEmail="{ item }">
                    <a :href="'mailto:' + item.AgentEmail" class="nowrap">{{ item.AgentEmail }}</a>
                </template>
                <template v-slot:item.AgentPhone="{ item }">
                    <a :href="'tel:' + item.AgentPhone" class="nowrap">{{ item.AgentPhone }}</a>
                </template>
                <template v-slot:item.$id="{ item }">
                    <span class="nowrap">
                        <v-btn color="q_new_b_1 white--text  body-2" :to="{ name: 'AgentDetail', params: { agentcode: item.AgentCode} }">View</v-btn>
                    </span>
                </template>
                <template v-slot:body.append>
                    <tr>
                        <td colspan=2>
                            <v-btn :disabled="loading" @click="downloadData">Download</v-btn>
                        </td>
                        <td colspan=8>
                            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
                        </td>
                    </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-row wrap>
                            <v-col cols="4">
                                <div class="overline">Email:</div>
                                <a href="'mailto:' + item.AgentEmail">{{ item.AgentEmail }}</a>
                            </v-col>
                            <v-col cols="8">
                                <div class="overline">Phone:</div>
                                <a href="'tel:' + item.AgentPhone">{{ item.AgentPhone }}</a>
                            </v-col>
                            <v-col cols="6" sm="4">
                                <div class="overline">Address:</div>
                                {{ item.AgentName }}<br>
                                {{ item.StreetAddress }}<br>
                                {{ item.City }}, {{ item.State }} {{ item.Zip }}<br>
                            </v-col>
                            <v-col cols="6" sm="4">
                                <div class="overline">Agent Code:</div>
                                {{ item.AgentCode }}<br>
                                <div class="overline">OptID:</div>
                                {{ item.OptID }}<br>
                            </v-col>
                        </v-row>
                    </td>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin"
import Agent from '@/store/Models/Agent'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QAgentsDirectoryTable",
    mixins: [QDataTableMixin],
    props: ['agent'],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'agent',
            sync_dialog: false,
            //use this to "preset" a filter
            filters: { 'Status': 'Active' },
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            agent_data: [],
            is_loading: false,
            server_total_items: 0,
            options: { sortBy: ['AgentName'], sortDesc: [false] },
            expanded: [],
        }
    },
    computed: {
        loading: function() {
            return this.is_loading
        },
        filter_keys: function() {
            return Agent.state().filter_keys;
        },
        all_data: function() {
            return this.agent_data
        },
        total_items: function() {
            return this.server_total_items;
        },
        the_headers: function() {
            var headers = [{
                    text: 'Name',
                    value: 'AgentName',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                },
                {
                    text: 'Email',
                    value: 'AgentEmail',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Phone',
                    value: 'AgentPhone',
                    sortable: true,
                    filterable: true
                }, {
                    text: 'State',
                    value: 'State',
                    sortable: true,
                    filterable: true,
                }, {
                    text: 'Contract',
                    value: 'ContractLevel',
                    sortable: true,
                    filterable: true,
                }, {
                    text: 'Leadership Level',
                    value: 'LeadershipLevel',
                    sortable: true,
                    filterable: true,
                }, {
                    text: 'Status',
                    value: 'Status',
                    sortable: true,
                    filterable: true,
                }, {
                    text: '(Opt) Last Active',
                    value: 'LastActivityDate',
                    sortable: true,
                    filterable: true,
                }, {
                    text: '',
                    value: '$id',
                    sortable: false,
                    filterable: false
                }
            ]
            if (this.$vuetify.breakpoint.mdAndDown) {
                headers.splice(1, 2); //remove email and phone
                headers.splice(5, 1); //remove LastActivityDate
            }
            return headers
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var params = {
                ...this.filters,
                ...opt
            };

            // make sure an existing field is not getting overwritten
            if('attr' in params)
            {
                params.attr = `${params.attr},HasDFLSMEInDownline`
            }
            else {
                params.attr = 'HasDFLSMEInDownline'
            }

            var g = this
            this.is_loading = true
            QuilityAPI.getAgentDownlineDirectory(this.agent.AgentCode, params, this.user.Agent.IsAgencyOwner)
                .then(function(json) {
                    g.$set(g, 'agent_data', json.data);
                    g.server_total_items = json.meta.total
                    g.is_loading = false;
                    Agent.commit((state) => {
                        if (json.meta.filters) {
                            state.availableFilters = json.meta.filters
                        }
                    })
                })
                .catch(function(err) {
                    if (err && err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    g.showError(err)
                    g.is_loading = false;
                });
            this.firstPull = false;
        }, 200),

        downloadData: debounce(function(opt) {
            opt.itemsPerPage = 'all';
            var params = {
                ...this.filters,
                ...opt
            };
            this.is_loading = true
            var g = this;
            QuilityAPI.getAgentDownlineDirectory(this.agent.AgentCode, params, this.user.Agent.IsAgencyOwner)
                .then(function(json) {
                    var fields = ['AgentCode', 'OptID', 'AgentName', 'OriginalAgentName', 'FirstName', 'LastName', 'MiddleName', 'NickName', 'PreferredName', 'AgentEmail', 'AgentPhone', 'StreetAddress', 'City', 'County', 'State', 'Zip', 'NPN', 'LeadershipLevel', 'ContractLevel', 'LeadershipLevelOverride', 'ProducerLevel', 'UplineAgentCode', 'SFGDirectAgentCode', 'BaseshopOwnerAgentName', 'UplineAgentName', 'SFGDirectAgentName', 'ContractStartDt', 'IsAgencyOwner', 'LastActivityDate'];
                    g.startDownload(json.data, fields)
                    g.is_loading = false
                })
                .catch(function(err) {
                    g.is_loading = false
                    if (typeof err == 'undefined') {
                        return
                    }
                    if (err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    if (typeof err.status == 'string' && err.status != '') {
                        g.showError(err.status)
                    }
                });
        }, 200),
    },
    watch: {

    },
    components: {}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
