<template>
    <v-row justify="center" class="mx-5">
        <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
        <v-col v-if="!loading && ticket.id > 0" cols=12 sm="8">
            <v-row cols="12" class="mb-5 d-flex">
                <v-col cols="1" flat color="transparent" class="pt-4 mr-3" max-width="75px">
                    <!--status display -->
                    <div v-if="ticket.status == 'new'" style="background-color:#0083EB;">
                        <div style="display:block; text-align:center; margin:0 auto;">
                            <v-icon class="pt-4" color="white" medium>fas fa-file-alt</v-icon>
                        </div>
                        <div class="pb-2 white--text" style="text-align:center;">
                            <v-text dark width="100%">
                                {{ticket.status}}
                            </v-text>
                        </div>
                    </div>
                    <div v-if="ticket.status == 'open'" style="background-color:#6ECF93">
                        <div style="display:block; text-align:center; margin:0 auto;">
                            <v-icon class="pt-4" color="white" medium>fas fa-file-alt</v-icon>
                        </div>
                        <div class="pb-2 white--text" style="text-align:center;">
                            <v-text dark width="100%">
                                {{ticket.status}}
                            </v-text>
                        </div>
                    </div>
                    <div v-if="ticket.status == 'pending'" style="background-color:#FF9F1C;">
                        <div style="display:block; text-align:center; margin:0 auto;">
                            <v-icon class="pt-4" color="white" medium>fas fa-file-alt</v-icon>
                        </div>
                        <div class="pb-2 white--text" style="text-align:center;">
                            <v-text dark width="100%">
                                {{ticket.status}}
                            </v-text>
                        </div>
                    </div>
                    <div v-if="ticket.status == 'solved'" style="background-color:#6ECF93;">
                        <div style="display:block; text-align:center; margin:0 auto;">
                            <v-icon class="pt-4" color="white" medium>fas fa-check</v-icon>
                        </div>
                        <div class="pb-2 white--text" style="text-align:center;">
                            <v-text dark width="100%">
                                {{ticket.status}}
                            </v-text>
                        </div>
                    </div>
                    <div v-if="ticket.status == 'closed'" style="background-color:#99CDF7;">
                        <div style="display:block; text-align:center; margin:0 auto;">
                            <v-icon class="pt-4" color="white" medium>fas fa-check-circle</v-icon>
                        </div>
                        <div class="pb-2 white--text" style="text-align:center;">
                            <v-text dark width="100%">
                                {{ticket.status}}
                            </v-text>
                        </div>
                    </div>
                    <!--/status display -->
                </v-col>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-col class="border-l-gray flex-grow-2" flat color="transparent">
                    <v-list-item three-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h2>{{ ticket.subject}}</h2>
                            </v-list-item-title>
                            <v-list-item-subtitle>Created : {{ formatDateDash(ticket.created_at) }}</v-list-item-subtitle>
                            <v-list-item-subtitle>Updated : {{ formatDateDash(ticket.updated_at) }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <q-ticket-comment-list :ticket="ticket"></q-ticket-comment-list>
        </v-col>
        <!--
            <v-col cols="12" sm="6" v-if="!loading &&  ticket.id > 0">
                <v-textarea v-model="comment" label="Submit reply"></v-textarea>
                <v-btn color="primary" sm max-width="200px" @click="submitComment">Submit</v-btn>
            </v-col>
            //-->
    </v-row>
</template>
<script>
//table data + search
import QSupportTicketsDataTable from '@/components/datatables/QSupportTicketsDataTable.vue';
import QSupportSubmenu from '@/components/navigation/Submenus/QSupportSubmenu.vue';
import SupportTicket from '@/store/Models/SupportTicket'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QTicketCommentList from '@/components/lists/QTicketCommentList.vue'

export default {
    props: ['agentcode', 'id'],
    data() {
        return {
            search: '',
            comment: null,
            ticket: {},
            loading: false
        }
    },
    computed: {

    },
    mounted: function() {
        this.refreshTicket(this.id, this.agentcode);
    },
    methods: {
        refreshTicket: function(id, agentcode) {
            var g = this
            this.loading = true;
            QuilityAPI.getAgentTicket(id, agentcode, this.role).then(function(json) {
                g.loading = false;
                g.$set(g, 'ticket', json.data);
            }).catch(function(err) {
                g.loading = false
            })
        },
        isCustomerService: function(id) {
            return this.ticket.requester_id != id;
        },
        submitComment: function() {
            var g = this;
            QuilityAPI.updateTicket(this.ticket.id, this.comment).then(function() {
                g.comment = null
                g.refreshTicket(g.ticket.id);
                g.trackSupport("Comment Submitted")
            }).catch((err) => {
				if (err.isDemoMode) {
					this.showError(err.msg)
				}
            });
        },
        trackSupport: function(what) {
            gtag('event', 'Support', {
                'event_category': 'Ticket',
                'event_label': what
            });
        },
        transferTicketAttachement: function(id) {
            QuilityAPI.transferTicketAttachement(this.ticket.id, this.comment).then((json) => {
                if (json.error) {
                    this.showError("Could transfer attachement. " + json.message || json.msg)
                    return;
                }
                this.showSuccess("Transfer complete!")
            })
        }
    },
    components: {
        QSupportTicketsDataTable,
        QSupportSubmenu,
        QTicketCommentList
    },
    watch: {
        'id': function(newV, old) {
            if (typeof newV != 'undefined') {
                var g = this;
                g.$nextTick(g.refreshTicket(newV, g.agentcode));
            }
        },
        'agentcode': function(newV, old) {
            if (typeof newV != 'undefined') {
                var g = this;
                g.$nextTick(g.refreshTicket(g.id, newV));
            }
        },
    }
}

</script>
