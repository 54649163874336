<template>
    <div>
        <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
        <v-row class="mx-5">
            <v-col cols=12>
                <q-lead-counts :counts="lead_counts" :loading="loading">
                </q-lead-counts>
            </v-col>
        </v-row>
    </div>
</template>
<script>
//table data + search
import QLeadCounts from '@/components/stats/QLeadCounts.vue'
import QMyLeadsSubmenu from '@/components/navigation/Submenus/QMyLeadsSubmenu.vue';
import { debounce } from 'vue-debounce'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    props: ['agent'],
    data() {
        return {
            loading: false,
            lead_counts: null,
        }
    },
    mounted: function() {
        if (this.lead_counts == null) {
            this.loadData();
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.loading = true
            QuilityAPI.getLeadCounts(this.agent.AgentCode, this.filters, opt).then(function(results) {
                if (typeof results.message != 'undefined') {
                    g.showError("Error communicated with Opt! You can try refreshing the page.")
                    g.loading = false
                    return
                }
                g.lead_counts = results
                g.loading = false
            }).catch(function(err) {
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                //g.showError(err.status)
            });
            this.firstPull = false;
        }, 200),
    },
    watch: {
        'agent.AgentCode': function() {
            this.$nextTick(this.loadData)
        }
    },
    components: {
        QLeadCounts,
        QMyLeadsSubmenu
    },
}

</script>
