<template>
    <div>
        <v-container fluid class="ma-0 pa-0" id="account_datatable'">
            <v-row>
                <v-col cols="6" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7">
                    <p class="q-display-1  pb-0 pt-0 mt-0 mb-0">
                        {{ title }}
                        <v-btn icon @click="refresh">
                            <v-icon>fas fa-redo-alt</v-icon>
                        </v-btn><br>
                    </p>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-start" v-show="showFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys" :checkboxesFilters="['CurrentPaymentMethodInd']" :show-reset="true"></q-filters>
            </v-row>
            <v-data-table :mobile-breakpoint="2" :items-per-page.sync="rows" :headers="the_headers" :items="all_data" item-key="ID" class="elevation-0 mb-6" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" :search="search" hide-default-footer>
                <template v-slot:item.PaymentMethodValidInd="{ item }">
                    <span v-if="item.PaymentMethodValidInd">
                        <v-icon>fas fa-check</v-icon>
                    </span>
                </template>
                <template v-slot:item.CurrentPaymentMethodInd="{ item }">
                    <span v-if="item.CurrentPaymentMethodInd">
                        <v-icon>fas fa-check</v-icon>
                    </span>
                </template>
                <template v-slot:item.Default="{ item }">
                    <v-btn v-if="!item.CurrentPaymentMethodInd" @click="setDefaultMethod(item.ID)">Make Default</v-btn>
                </template>
                <template v-slot:item.Delete="{ item }">
                    <v-btn icon color="red" v-if="!item.CurrentPaymentMethodInd" @click="deletePaymentMethod(item.ID)">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </template>
                <template v-slot:footer="{ items }">
                    <v-btn @click="show_payment_form = true">Add Payment Method</v-btn>
                </template>
            </v-data-table>
        </v-container>
        <q-save-payment-profile :agent-code="agentCode" v-model="show_payment_form" v-on:newcard="refresh"></q-save-payment-profile>
    </div>
</template>
<script>
import QGenericDataTableMixin from "../QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import QSavePaymentProfile from '@/components/authorizenet/QSavePaymentProfile.vue';

export default {
    name: "QAgentPaymentProfilesDataTable",
    props: ['agentCode'],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'account',
            //use this to "preset" a filter
            filters: {

            },
            options: {
                'sortBy': [],
                'sortDesc': [true],
                'itemsPerPage': 100
            },
            expanded: [],
            the_data: [],
            data_filters: null,
            valid: false,
            warning: null,
            show_payment_form: false
        }
    },
    mounted: function() {

    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return [];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Payment Type',
                    value: 'PaymentType',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'Last Four',
                    value: 'PaymentLastFour',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'Default',
                    value: 'CurrentPaymentMethodInd',
                    sortable: true,
                    align: "center"
                },
                {
                    text: '',
                    value: 'Default',
                    sortable: true,
                    align: "center"
                },
                {
                    text: '',
                    value: 'Delete',
                    sortable: true,
                    align: "center"
                }

            ]
        },
        available_filters: function() {
            return this.data_filters
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            opt.needAvailableFilters = false
            QuilityAPI.getAgentPaymentProfiles(this.agentCode, this.filters, opt).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, 'the_data', json.data);
                g.total_num = json.total
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
            this.firstPull = false;
        }, 200),
        refresh: function() {
            this.the_data = [];
            this.refreshData();
        },

        setDefaultMethod: function(id) {
            var g = this
            this.data_loading = true
            QuilityAPI.setDefaultPaymentMethod(this.agentCode, id).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, 'the_data', json.data);
                g.total_num = json.total
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
        },

        deletePaymentMethod: function(id) {
            var g = this
            this.data_loading = true
            QuilityAPI.deletePaymentMethod(this.agentCode, id).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                if (typeof json.msg != 'undefined') {
                    g.showError(json.msg);
                    return
                }
                g.$set(g, 'the_data', json.data);
                g.total_num = json.total
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
        },
        goToTop: function() {
            this.$vuetify.goTo('#form', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
    },
    watch: {
        'options': function() {
            this.refreshData();
        },
        'agentCode': function(newV) {
            if (typeof newV != 'undefined' && newV != null) {
                this.the_data = []
                this.refreshData();
            }
        },
    },
    components: {
        QSavePaymentProfile
    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>