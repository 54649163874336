<template>
    <div>
        <v-list dense two-line class="agent">
            <v-subheader>UPLINE</v-subheader>
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <template v-for="(up, index) in upline">
                <div v-if="index != 0" class="text-center">
                    <v-divider icon="fas fa-arrow-circle-up">
                        <v-icon small style="align-self: center">fas fa-arrow-circle-up</v-icon>
                    </v-divider>
                </div>
                <v-list-item :key="up.AgentCode">
                    <v-list-item-avatar @click="" @click="clickAgent(up.AgentCode)">
                        <v-img :src="up.Avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content @click="clickAgent(up.AgentCode)">
                        <v-list-item-title>
                            <v-badge color="blue" :content="up.ContractLevel" icon="fas fa-crown">
                                {{ up.AgentName }}
                            </v-badge>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ up.LeadershipLevel }}
                            <span v-if="up.Status != 'Active'"><em class="red--text"> - {{ up.Status }}</em></span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-row>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mx-2" depressed icon small v-bind="attrs" v-on="on" v-clipboard:copy="up.AgentEmail" v-clipboard:success="handleCopyStatus">
                                        <v-icon color="q_blue_1">fas fa-envelope</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ up.AgentEmail }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mx-2" depressed icon small v-bind="attrs" v-on="on" v-clipboard:copy="up.AgentPhone" v-clipboard:success="handleCopyStatus">
                                        <v-icon color="q_blue_1">fas fa-phone</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ up.AgentPhone }}</span>
                            </v-tooltip>
                        </v-row>
                    </v-list-item-action>
                </v-list-item>
            </template>
        </v-list>
        <v-divider></v-divider>
        <v-btn v-if="this.hasPermission(['manage:Agent'])" class="pa-6" text small color="q_blue_1" right @click="show_hierarchy_log = true">View hierarchy change Log</v-btn>
        <v-dialog v-model="show_hierarchy_log" width="600">
            <v-card class="pa-6 text-center">
                <q-hierarchy-audit-table :agent="agent"></q-hierarchy-audit-table>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QHierarchyAuditTable from '@/components/datatables/Audit/QHierarchyAuditTable.vue'
export default {
    props: ['agent'],
    data: function() {
        return {
            upline: [],
            loading: false,
            show_hierarchy_log: false
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {},
    methods: {
        loadData: function(opt) {
            var g = this;
            var d = new Date()
            this.loading = true
            QuilityAPI.getAgentUpline(this.agent.AgentCode, [], this.isAgent).then(function(json) {
                g.upline = json.data;
                g.loading = false;
            });
        },
        clickEmail: function(em) {
            document.location.href = "mailto:" + em
        },
        clickPhone: function(ph) {
            document.location.href = "tel:" + ph
        },
        clickAgent: function(ac) {
            if (this.hasPermission(['manage:Agent'])) {
                this.$router.push('/agents/' + ac)
            }
            if (this.hasRole(["AgencyOwner"])) {
                this.$router.push('/agents/' + ac)
            }
            if (ac == this.user_meta.AgentCode) {
                this.$router.push('/agents/' + ac)
            }
        },
        handleCopyStatus(status) {
            this.showInfo("Copied to clipboard")
        },
    },
    components: {
        QHierarchyAuditTable
    }
}

</script>
