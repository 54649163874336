<template>
    <div class="labeled-switcher" :class="switcherClass" :style="switcherStyle">
		<div
			class="labeled-switcher__label"
			:class="beforeLabelClass"
			@click="onBeforeLabelClick"
		>
			<div class="labeled-switcher__label-sizer">{{ beforeLabel }}</div>
			<div class="labeled-switcher__label-viewer">{{ beforeLabel }}</div>
		</div>

		<v-switch
			v-model="computedValue"
			v-bind="$attrs"
			:disabled="disabled"
			label=""
			class="labeled-switcher__switch"
		/>

		<div
			class="labeled-switcher__label"
			:class="afterLabelClass"
			@click="onAfterLabelClick"
		>
			<div class="labeled-switcher__label-sizer">{{ afterLabel }}</div>
			<div class="labeled-switcher__label-viewer">{{ afterLabel }}</div>
		</div>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		value: String,
		labelBefore: { type: [Object, String], default: () => ({ label: 'Before', value: 'After' }) },
		labelAfter: { type: [Object, String], default: () => ({ label: 'After', value: 'After' }) },
		disabled: Boolean,
		color: String,
	},
	computed: {
		computedValue: {
			get () {
				return this.value === this.beforeValue ? false : true
			},
			set (val) {
				const res = !val ? this.beforeValue : this.afterValue
				this.$emit('input', res)
			}
		},
		beforeLabel () {
			return typeof this.labelBefore === 'string'
				? this.labelBefore
				: this.labelBefore.label
		},
		beforeValue () {
			return typeof this.labelBefore === 'string'
				? this.labelBefore
				: this.labelBefore.value
		},
		afterLabel () {
			return typeof this.labelAfter === 'string'
				? this.labelAfter
				: this.labelAfter.label
		},
		afterValue () {
			return typeof this.labelAfter === 'string'
				? this.labelAfter
				: this.labelAfter.value
		},
		beforeLabelClass () {
			return [
				this.value === this.beforeValue && 'labeled-switcher__label--active',
			]
		},
		afterLabelClass () {
			return [
				this.value === this.afterValue && 'labeled-switcher__label--active',
			]
		},
		switcherClass () {
			return [
				this.disabled && 'labeled-switcher--disabled',
			]
		},
		switcherStyle () {
			if (!this.color) { return {} }
			return {
				'--color': this.color,
			}
		},
	},
	methods: {
		onBeforeLabelClick () {
			if (this.disabled) { return }
			if (this.value === this.beforeValue) { return }
			this.$emit('input', this.beforeValue)
		},
		onAfterLabelClick () {
			if (this.disabled) { return }
			if (this.value === this.afterValue) { return }
			this.$emit('input', this.afterValue)
		},
	},
}
</script>

<style lang="scss">
.labeled-switcher {
	padding: 0.75rem 0;
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
}

.labeled-switcher__label {
	position: relative;
	font-size: 16px;
	line-height: 1;
	font-weight: 300;
	color: #000F2C;
	cursor: pointer;

	&--active {
		font-weight: bold;
	}
}

.labeled-switcher__label-sizer {
	opacity: 0;
	z-index: -1;
	font-weight: bold;
}

.labeled-switcher__label-viewer {
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
}

.labeled-switcher--disabled {
	.labeled-switcher__label {
		pointer-events: none;
		opacity: 0.4;
	}
}

.labeled-switcher__switch.v-input--switch {
	margin: 0 0.25rem;
	padding: 0;

	& .v-input--selection-controls__input {
		width: 40px !important;
		margin-right: 0;
	}

	& .v-input--switch__thumb {
		color: var(--color) !important;
	}

	& .v-input--switch__track {
		opacity: 1 !important;
		color: #E2E2E2 !important;
	}
}
</style>
