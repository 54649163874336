<template>
	<v-card>
		<v-card-title class="q_leads_1 white--text d-flex flex-nowrap">
			<div class="flex-grow-1 font-weight-bold">Find Certified Agents for {{ pool }} Reset Type</div>
			<v-btn
				icon
				class="ml-2 align-self-start flex-shrink-0"
				color="#fff"
				@click="$emit('close')"
			>
				<v-icon>fa fa-times</v-icon>
			</v-btn>
		</v-card-title>

		<v-card-text :class="$vuetify.breakpoint.xs && 'px-4'">
			<v-alert v-if="error" class="mt-10 mb-6" type="error">
				There was an issue loading Certified Agents data or Calendar Links.
			</v-alert>

			<div v-else class="d-flex flex-column">
				<div class="d-flex flex-wrap justify-space-between" style="column-gap:3rem; row-gap:1rem;">
					<v-radio-group
                        v-model="statTypeFilter"
						label="Select Filter Option"
						:disabled="saving"
						hide-details
					>
						<v-radio
							v-for="option in statTypeOptions" :key="option.value"
							:value="option.value"
							:label="option.label"
							color="q_leads_1"
						/>
					</v-radio-group>

					<v-text-field
						v-model="search"
						:disabled="isDemoMode"
						prepend-icon="icon-q-search"
						label="Search agent"
						style="width:100%; max-width:30rem;"
						color="q_leads_1"
						class="align-self-end"
						single-line clearable hide-details
					/>
				</div>

				<v-data-table
					:headers="headers"
					:items="agentItems || []"
					:search="search"
					:loading="(loading || saving) && !expandedAgent"
					item-key="AgentID"
					:expanded.sync="expandedItems"
					show-expand
					single-expand
					:footer-props="{ itemsPerPageOptions:[10, 25, 50, 100] }"
					:mobile-breakpoint="0"
					class="elevation-0 certified-agents-table mt-8"
					@click:row="onRowClick"
				>
					<template #item.AgentName="{ item }">
						<strong>{{ item.AgentName }}</strong> - {{ item.LeadershipLevel }} ({{ item.AgentCode }})
					</template>
					<template #item.hasAdded="{ value }">
						<v-icon v-if="value" color="#6ECF93">fas fa-check</v-icon>
					</template>
					<template #expanded-item="{ headers, item }">
						<td :colspan="headers.length" :class="$vuetify.breakpoint.xs ? 'pa-2 pt-1' : 'pa-4 pt-1'">
							<v-card elevation="0" :loading="saving" :loader-height="2">
								<v-card-title class="certified-agents-table__card-title">
									<p v-html="cardTitle" />
								</v-card-title>
								<v-card-text>
									<div v-if="certifiedAgentDescription" class="pt-4" v-html="certifiedAgentDescription" />
									<div class="mt-6 d-flex align-start" style="gap:1rem;">
										<QUrlInput
											class="url-input"
											label="Calendar URL"
											v-model="expandedCalendarURL"
											:disabled="saving"
											:error-messages="duplicatesErrorMessage"
											requiredMessage="Calendar URL is required"
											invalidLinkMessage="This does not appear to be a valid URL. This may cause problems when redirecting to this link."
											:isValid.sync="isExpandedLinkValid"
										/>
										<v-btn v-if="!item.hasAdded" class="mt-3" color="primary" :disabled="isAddButtonDisabled" @click="$emit('add-link', expandedCalendarURL, item)">Add</v-btn>
										<div v-else class="d-flex align-start mt-3" style="gap:0.5rem;">
											<v-btn color="warning" small fab icon :disabled="isUpdateButtonDisabled" @click.stop="$emit('update-link', expandedCalendarURL, item)">
												<v-icon>fas fa-save</v-icon>
											</v-btn>
											<v-btn color="error" small fab icon :disabled="saving" @click.stop="$emit('remove-link', item, item.calendarURL)">
												<v-icon>fas fa-times-circle</v-icon>
											</v-btn>
										</div>
									</div>
								</v-card-text>
							</v-card>
						</td>
					</template>
				</v-data-table>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import QUrlInput from '@/components/utils/QUrlInput.vue'

export default {
    components: {
        QUrlInput,
    },
	props: {
		value: { type: Object, default: () => ({ statType: 'Baseshop' }) },
		agentCalendarLinks: { type: Object, required: true },
		certifiedAgents: { type: Object, required: true },
		pool: String,
		saving: Boolean,
		calendarUrlKey: String,
	},
    data () {
        return {
			expandedItems: [],
            expandedCalendarURL: '',
            isExpandedLinkValid: false,
            search: '',
			statTypeOptions: [
				{ label: 'Baseshop', value: 'Baseshop' },
				{ label: 'Total Agency', value: 'TotalAgency' },
				{ label: 'All Hierarchies', value: 'AllHierarchies' },
			],
        }
    },
    computed: {
        statTypeFilter: {
            get () {
                return this.value.statType
            },
            set (value) {
                const filters = {
                    ...this.value,
                    statType: value,
                }

                this.$emit('input', filters)
            },
       },
        isAddButtonDisabled () {
            return !this.expandedCalendarURL ||
				!this.isExpandedLinkValid ||
				!!this.duplicatesErrorMessage ||
				this.saving
        },
        isUpdateButtonDisabled () {
            return this.isAddButtonDisabled || this.expandedAgent?.calendarURL === this.expandedCalendarURL
        },
		duplicatesErrorMessage () {
			if (!this.agentItems || !this.expandedAgent || !this.expandedCalendarURL) { return '' }
			const duplicate = this.agentCalendarLinks.items?.find(item => this.expandedAgent.AgentCode !== item.Agent.AgentCode && this.expandedCalendarURL === item.CalendarURL)
			if (!duplicate) { return '' }
			const { AgentCode, AgentName } = duplicate.Agent
			return `This calendar link is already in the ${this.pool} reset pool (at agent ${AgentName} - ${AgentCode}). You can edit this link or ${AgentName}'s' calendar in the table.`
		},
        agentItems() {
            const items = this.certifiedAgents.items?.map(item => {
                const agent = this.agentCalendarLinks.items?.find((linksItem) => linksItem.Agent.AgentCode === item.AgentCode)
                return {
                    ...item,
                    hasAdded: !!agent,
                    calendarURL: agent ? agent.CalendarURL : '',
                }
            })

            return items?.sort((a, b) => a.AgentName.localeCompare(b.AgentName)) || null
        },
		error () {
            return this.certifiedAgents.error || this.agentCalendarLinks.error
		},
        loading() {
            return this.certifiedAgents.loading || this.agentCalendarLinks.loading
        },
		headers () {
			return [
				{
                    text: 'Certified Agent',
                    value: 'AgentName',
                },
				{
                    text: 'Added',
                    value: 'hasAdded',
                    align: 'center',
                    sortable: false,
                    filterable: false,
					width: 80,
                },
				{
					text: '',
					value: 'data-table-expand',
				},
			]
		},
		expandedAgent () {
			if (!this.expandedItems.length || !this.agentItems) { return null }

			return this.agentItems.find(({ AgentCode }) => AgentCode === this.expandedItems[0].AgentCode)
		},
		certifiedAgentDescription () {
			if (!this.expandedAgent) { return '' }
			if (this.expandedAgent.hasAdded) {
				let content = `<p>Agent <strong>${this.expandedAgent.AgentName}</strong> is already in your ${this.pool} reset pool. You can update the Calendar URL or remove it from your reset pool.</p>`
				if (this.expandedAgent[this.calendarUrlKey] && this.expandedAgent.calendarURL !== this.expandedAgent[this.calendarUrlKey]) {
					content = content + `<p>The ${this.expandedAgent.AgentName}'s calendar link in your ${this.pool} reset pool is different from the ${this.pool} Calendar URL (<strong>${this.expandedAgent[this.calendarUrlKey]}</strong>) in agent config.</p>`
				}
				return content
			} else {
				return `<p v-else>Agent <strong>${this.expandedAgent.AgentName}</strong> is not in your ${this.pool} reset pool. You can add the Calendar URL to your reset pool.</p>`
			}
		},
		cardTitle () {
			if (!this.expandedAgent) { return '' }
			return this.expandedAgent.hasAdded ? 'Edit Calendar URL' : 'Add Calendar URL'
		},
    },
	watch: {
		expandedItems () {
			const agent = this.expandedItems[0]
			if (!agent) {
				this.expandedCalendarURL = ''
			} else {
				this.expandedCalendarURL = agent.hasAdded
					? agent.calendarURL ?? ''
					: agent[this.calendarUrlKey] ?? ''
			}
		},
		agentItems (newValue) {
			// workaround due to loss of reactivity for expandedItems[0]
			const agent = newValue?.find(agent => agent.AgentCode === this.expandedItems[0]?.AgentCode)
			if (!agent) { return }
			if (!agent.hasAdded && this.expandedItems[0].hasAdded) {
				this.expandedCalendarURL = agent[this.calendarUrlKey] ?? ''
			}
		},
	},
    methods: {
		onRowClick(event, item) {
            item.expand(!item.isExpanded)
        },
    },
}
</script>

<style lang="scss" scoped>
.certified-agents-table {
	&__card-title {
		padding: 0.25rem 0 0;
		background-color: #f4f4f4;
		color: #00000099;

		p {
			margin-bottom: 0 !important;
			font-size: 0.875rem;
			font-weight: 600;
		}
	}

	::v-deep .v-data-table__expanded__content {
		box-shadow: none !important;
		background-color: #f4f4f4;
	}
	::v-deep .v-data-table__expanded.v-data-table__expanded__row {
		background-color: #f4f4f4;
	}
	::v-deep .v-data-footer {
		.v-data-footer__select {
			.v-select {
				margin-left: 1rem;
			}
		}
		.v-data-footer__pagination {
			margin: 0 1rem 0 2rem;
		}
		.v-btn {
			margin: 0;
		}
	}
}

.url-input {
	width: 100%;

	&.v-input--is-disabled {
		::v-deep .v-messages {
			color: #ff5252 !important;
		}
	}
}
</style>
