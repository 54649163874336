<template>
    <div>
        <v-dialog ref="the_dialog" :value="value" @input="handlePopup" width="700px" max-width="70%">
            <v-card class="pa-6 text-center" min-height="">
                <h4 class="q-display-1 pa-5 white--text text-center q_leads_1">
                    Agent SMS Correspondence
                </h4>
                <q-form-sms-correspondence ref="agentForm" :agent-code="agentcode" v-on:saved="emitSaved"></q-form-sms-correspondence>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QFormSmsCorrespondence from '@/components/agent_admin/QFormSmsCorrespondence.vue'

export default {
    props: ['value'],
    data() {
        return {
            showPopup: this.value
        }
    },
    computed: {
        'agentcode': function() {
            if (typeof this.$route.params.agentcode == 'undefined') {
                return null
            }
            return this.$route.params.agentcode;
        }
    },
    methods: {
        handlePopup(e) {
            this.$emit('input', e)
        },
        emitSaved() {
            this.$emit('input', false)
        }

    },
    watch: {

    },
    components: {
        QFormSmsCorrespondence
    }
}

</script>
