<template>
    <div>
        <v-dialog v-model="show" width="60%">
            <v-card class="pa-6 text-center" min-height="">
                <v-card-title>
                    <h4 class="q-display-1 pa-5 white--text text-center q_leads_1" style="min-width:100%;">
                        NPN Change Tool - {{agent.AgentName}}
                    </h4>
                    <v-progress-linear indeterminate v-if="writing_numbers_loading"></v-progress-linear>
                </v-card-title>
                <v-card-text v-if="!writing_numbers_loading" id="form">
                    <v-progress-linear indeterminate v-if="writing_numbers_updating"></v-progress-linear>
                    <v-form v-model="form_valid" ref="form" :disabled="writing_numbers_updating">
                        <v-row>
                            <v-col cols="5">
                                <v-text-field label="Current NPN" v-model="NPNPayload.oldNPN"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pt-10">
                                <v-icon>fas fa-arrow-right</v-icon>
                            </v-col>
                            <v-col cols="5">
                                <v-text-field label="New NPN" v-model="NPNPayload.newNPN" :rules="[form_rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="AvailableWritingNumbers.length > 0">
                                <h5>Associated Writing Numbers</h5>
                                <v-list class="text-left">
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-checkbox v-model="check_all"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title label class="carrier-name">
                                                <span>Select All</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-for="writing_number in AvailableWritingNumbers" :key="writing_number.CarrierID + '_' + writing_number.WritingNumber">
                                        <v-list-item-action>
                                            <v-checkbox v-model="NPNPayload.WritingNumberIDs" :value="writing_number.ID"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title label class="carrier-name">
                                                <span>{{ writing_number.CarrierName }} : {{ writing_number.WritingNumber }} <span v-if="!writing_number.Active"><em> - Inactive</em></span></span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-alert type="warning" v-if="warning">
                        {{ warning }}
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="changeNPN">Commit Change</v-btn><v-btn @click="closeDialog">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    props: ['agent'],
    data() {
        return {
            AvailableWritingNumbers : [],
            writing_numbers_loading : false,
            writing_numbers_updating : false,
            NPNPayload : {},
            show : false,
            form_valid : false,
            check_all : false,
            warning : null
        }
    },
    mounted: function() {
    },
    methods: {
        showNPNChangeTool(npn, npntype){
            this.NPNPayload = {
                oldNPN : npn,
                newNPN : null,
                isDBANPN : npntype == 'business',
                WritingNumberIDs : []
            }
            this.AvailableWritingNumbers = [];
            this.show = true;
            this.loadAvailableWritingNumbers(npntype);
        },
        loadAvailableWritingNumbers(npntype, agent) {
            this.writing_numbers_loading = true;
            var g = this;
            if (npntype == "personal") {
                var func = QuilityAPI.getCarrierWritingNumbers(g.agent.AgentCode)
            } else {
                func = QuilityAPI.getCarrierWritingNumbersAgency(g.agent.AgentCode)
            }
            func.then(function(resp) {
                g.writing_numbers_loading = false;
                if (resp.success !== false) {
                    resp.data.sort((a, b) => {
                        let cna = a.CarrierName,
                            cnb = b.CarrierName
                        if (cna < cnb) {
                            return -1;
                        }
                        if (cna > cnb) {
                            return 1;
                        }
                        return 0;
                    })
                    g.AvailableWritingNumbers = resp.data
                    g.check_all = true
                } else {
                    g.showError(resp.reason);
                }
            });
        },
        changeNPN () {
            var g = this;
            g.writing_numbers_updating = true;
            if (!this.$refs['form'].validate()) {
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                g.writing_numbers_updating = false;
            }
            QuilityAPI.changeNpnNumber(g.agent.AgentCode, this.NPNPayload).then((json) => {
                if (json.success === true){
                    g.showSuccess('NPN Number has been changed!')
                    g.$emit('npnchange', this.NPNPayload.newNPN)
                    g.closeDialog()
                    return
                }
                g.showError('There was a problem! NPN could not be updated')
                return
            })
        },
        //slides the form to the top
        goToTop: function() {
            this.$vuetify.goTo('#form', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
        closeDialog: function(){
            this.check_all = false
            this.NPNPayload = {
                oldNPN : null,
                newNPN : null,
                isDBANPN : false,
                WritingNumberIDs : []
            }
            this.AvailableWritingNumbers = [];
            this.show = false;
            this.writing_numbers_loading = false
            this.writing_numbers_updating = false
        }
    },
    watch: {
        'check_all' : function(newV){
            if (newV){
                this.NPNPayload.WritingNumberIDs = this.AvailableWritingNumbers.map(a => a.ID);
            }
        },
        'NPNPayload.WritingNumberIDs' : function(newV){
            if (newV.length < this.AvailableWritingNumbers.length){
                this.$nextTick(() => {this.check_all = false})
            }
        }
    },
    components: {
    }
}

</script>
