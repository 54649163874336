<template>
    <div>
        <v-row>
            <v-col>
                <h2>Documents
                    <v-btn icon @click="updateDocumentList">
                        <v-icon>fas fa-redo-alt</v-icon>
                    </v-btn>
                    <br />
                    <v-btn outlined color="primary" v-if="hasPermission('manage:Agent')" @click="editDoc()">Upload</v-btn>
                </h2>
            </v-col>
            <v-col>
                <QComplianceCorner :agent="agent.AgentCode" />
            </v-col>
        </v-row>
		<v-row>
			<v-col cols="6">
				<v-text-field v-model="search" :disabled="isDemoMode" label="Search for a document" clearable></v-text-field>
			</v-col>
		</v-row>

        <QExpandableDataTable :search="search" :headers="the_headers" :visibleHeaders="visibleHeaders" :items="documentList" item-key="ID" class="mx-n4 leaderboard-datatable q-agent-documents-table" :loading="loading" @click:row="previewDocument" :footer-props="{itemsPerPageOptions:[25,50,100]}" :items-per-page.sync="rows" sort-by="LastChangeDate" :sort-desc="true">
            <template v-slot:item.ID="{ item }">
                <span class="nowrap">
                    <v-btn color="q_blue_1" small fab icon @click.stop="downloadDoc(item)">
                        <v-icon>fas fa-download</v-icon>
                    </v-btn>
                    <v-btn color="q_agency_1" small fab icon @click.stop="previewDocument(item)">
                        <v-icon>fas fa-eye</v-icon>
                    </v-btn>
                    <v-btn color="q_resources_1" small v-if="hasPermission('manage:Agent')" fab icon @click.stop="editDoc(item)">
                        <v-icon>fas fa-edit</v-icon>
                    </v-btn>
                </span>
            </template>
            <template v-slot:item.DocTitle="{ item }">
                <span>
                    <strong>{{item.DocTitle}}</strong>
                </span>
            </template>
            <template v-slot:item.DocType="{ item }">
                <span>
                    <v-icon color="q_new_b_1" left>{{ getDocIcon(item.DocType) }}</v-icon>
                    {{item.DocType}}
                </span>
            </template>
            <template v-slot:item.ExpDate="{ item }">
                <span>{{ formatDate(item.ExpDate) }}</span>
            </template>
            <template v-slot:item.Delete="{ item }">
                <span v-if="item.DocType != 'delete'">
                    <v-btn color="red" v-if="hasPermission('manage:Agent')" small fab icon @click.stop="confirmDeleteDoc(item)">
                        <v-icon>fas fa-times-circle</v-icon>
                    </v-btn>
                </span>
                <span v-else>
                    <v-progress-circular indeterminate></v-progress-circular>
                </span>
            </template>
        </QExpandableDataTable>

		<!-- Show PDF preview dialog -->
        <v-dialog v-model="showPDFPreview" width="80%">
            <v-card style="position:relative" class="q-agent-documents-table__docs-preview">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <PdfPreview v-if="showPDFPreview" :value="previewDocumentUrl" />
            </v-card>
        </v-dialog>
		<!-- Show document preview dialog -->
        <v-dialog v-model="showDocPreview" width="80%">
            <v-card v-if="previewDocumentDocType == 'image'" style="position:relative">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-img v-if="previewDocumentDocType == 'image'" :src="previewDocumentUrl"></v-img>
            </v-card>
            <v-card v-else style="position:relative" class="q-agent-documents-table__docs-preview">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <VueDocPreview :url="previewDocumentUrl" :type="previewDocumentDocType" />
                <v-loader class="q-agent-documents-table__preview-loader" />
            </v-card>
        </v-dialog>

		<!-- Show edit document dialog -->
        <v-dialog v-model="showEditDoc" width="100%" max-width="800px">
			<v-form v-model="form_valid" :disabled="saving">
				<v-card v-if="showEditDoc">
					<div style="height:4px">
						<v-progress-linear v-if="saving" indeterminate />
					</div>
					<v-card-title class="font-weight-bold pb-6">
						{{ editDialogTitle }}
					</v-card-title>
					<v-card-text>
						<v-checkbox v-if="!currentDoc.ID" v-model="uploadMultipleDocs" label="Upload multiple documents" hide-details class="mt-0 pt-0 mb-2" />
						<QFileUpload
							:key="dropzoneKey"
							:upload-url="uploadUrl"
							upload-name="document"
							:params="currentDoc.ID ? {} : { 'AgentCode' : agent.AgentCode }"
							required
							:rules="[form_rules.required]"
							:accepted-files="acceptedFiles"
							:max-files="uploadMultipleDocs ? null : 1"
							:text="editDialogDropzoneLabel"
							class="mb-6"
							@complete="uploadComplete($event)"
						/>
						<template v-if="!uploadMultipleDocs">
							<v-text-field id="DocTitle" v-model="currentDoc.DocTitle" label="Title" :rules="[form_rules.required]" required />
							<v-text-field v-model="currentDoc.DocDesc" label="Description" />
							<v-combobox
								v-model="currentDoc.DocumentTags"
								:items="documentTags"
								chips
								clearable
								label="Document tags"
								multiple
							>
								<template v-slot:selection="{ attrs, item, select, selected }">
									<v-chip
										v-bind="attrs"
										:input-value="selected"
										close
										@click="select"
										@click:close="removeTag(item)"
									>
										<strong>{{ item }}</strong>&nbsp;
									</v-chip>
								</template>
							</v-combobox>
							<QDatePicker v-model="currentDoc.ExpDate" label="Expiration Date" />
						</template>
					</v-card-text>
					<v-card-actions v-if="!uploadMultipleDocs" class="d-flex justify-center flex-wrap py-6" style="gap:1rem;">
						<v-btn color="primary" :disabled="isSaveChangesDisabled" @click="saveChanges">Save Changes</v-btn>
						<v-btn outlined color="secondary" :disabled="saving" @click="showEditDoc = false">Cancel</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
        </v-dialog>

		<!-- Confirm delete document dialog -->
		<v-dialog v-model="dialogConfirmDelete" width="500">
            <v-card v-if="dialogConfirmDelete" :loading="removing">
                <v-card-title class="headline error" color="error">
                    <h4 class="q_white-text">Move <span>(PERMANENT)</span> to the trash? </h4>
                </v-card-title>
                <v-card-text class="pt-3">
                    <p class="body">Are you sure you want to <strong>PERMANENTLY</strong> remove this content? This will delete the document <strong>{{ currentDoc.DocTitle }}</strong></p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn class="ml-5" color="error" outlined :disabled="removing" @click="deleteDoc(currentDoc)">
                        Delete
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-5" color="error" depressed @click="dialogConfirmDelete = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import QFileUpload from '@/components/utils/QFileUpload.vue'
import QDatePicker from '@/components/utils/QDatePicker.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import PdfPreview from '@/CMS/editor/components/PdfPreview.vue'
import VueDocPreview from 'vue-doc-preview'
import QExpandableDataTable from '../datatables/QExpandableDataTable.vue'
import QComplianceCorner from './QComplianceCorner.vue'

export default {
    props: ['agent'],
    data () {
        return {
            loading: false,
            saving: false,
            removing: false,
            documentList: [],
            showPDFPreview: false,
            showDocPreview: false,
            previewDocumentDocType: null,
            previewDocumentUrl: null,
            search: null,
            rows: 25,
            showEditDoc: false,
            currentDoc: {},
			documentTags: [
				'Agent Contract',
				'E&O Cert',
				'Contract',
				'Signature',
				'SureLCAgreement',
			],
			form_valid: false,
			dialogConfirmDelete: false,
			acceptedFiles: 'application/vnd.ms-excel, application/csv, application/vnd.ms-word,.csv,.txt,.jpg,.png,.gif,.docx,.doc,.xlsx,.pdf,.msg',
			uploadMultipleDocs: false,
			dropzoneKey: 1, // workaround due to reactivity issue with QFileUpload (vue2-dropzone) maxFiles
        }
    },
    mounted () {
        this.updateDocumentList()
    },
    computed: {
        the_headers: function() {
            var headers = [{
                text: '',
                value: 'ID',
            }, {
                text: 'Title',
                value: 'DocTitle',
            }, {
                text: 'Date',
                value: 'LastChangeDate'
            }, {
                text: 'Exp Date',
                value: 'ExpDate'
            }, {
                text: 'Type',
                value: 'DocType',
            }]
            if (this.hasPermission('manage:Agent')) {
                headers.push({
                    text: 'Delete',
                    value: 'Delete',
                    width: "100"
                })
            }
            return headers;
        },
        visibleHeaders() {
            let hiddenHeaders = []

            if (this.$vuetify.breakpoint.mdAndDown) {
                // hiddenHeaders = ['DocType',]
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                hiddenHeaders = ['DocType', 'LastChangeDate']
            }

            if (this.$vuetify.breakpoint.xs) {
                hiddenHeaders = ['ID', 'DocType', 'LastChangeDate', 'Delete']
            }

            return this.the_headers.filter((h) => !hiddenHeaders.includes(h.value))
        },
		uploadUrl () {
			return this.currentDoc.ID
				? `/api/private/agent_document/${this.currentDoc.ID}`
				: '/api/private/agent_document'
		},
		isSaveChangesDisabled () {
			return (this.saving || this.loading) ||
				!this.form_valid ||
				!this.currentDoc.ID ||
				!this.wasCurrentDocChanged
		},
		wasCurrentDocChanged () {
			const ix = this.indexByKey(this.currentDoc.ID, this.documentList, 'ID')
			if (ix === -1) { return false }
			const keys = ['DocTitle', 'DocDesc', 'DocumentTags', 'ExpDate']
			const doc = this.documentList[ix]
			return keys.some(key => {
				if (key === 'DocumentTags') {
					const isBothEmpty = (!doc[key] || !doc[key].length) && (!this.currentDoc[key] || !this.currentDoc[key].length)
					if (isBothEmpty) { return false }
					const isDiffLength = doc[key]?.length !== this.currentDoc[key]?.length
					if (isDiffLength) { return true }
					return this.currentDoc[key].some(tag => !doc[key].includes(tag))
				} else {
					const isBothNullish = !this.currentDoc[key] && !doc[key]
					if (isBothNullish) { return false }
					return this.currentDoc[key] !== doc[key]
				}
			})
		},
		editDialogTitle () {
			if (this.uploadMultipleDocs) { return 'New Documents' }
			return this.currentDoc.ID ? 'Edit Document' : 'New Document'
		},
		editDialogDropzoneLabel () {
			if (this.uploadMultipleDocs) { return 'Drop documents here to upload' }
			return this.currentDoc.ID ? 'Drop new version of document here' : 'Drop document here to upload'
		},
    },
    methods: {
        uploadComplete (json) {
			if (!json.data.success) {
				this.showError(`${json.data.message}<br>`)
				return
			}
			if (this.uploadMultipleDocs) {
				this.documentList = [json.data.file, ...this.documentList]
				this.showSuccess(`Agent document (<strong>${json.data.file.DocTitle}</strong>) uploaded successfully<br>`)
				return
			}
			const ix = this.indexByKey(this.currentDoc.ID, this.documentList, 'ID')
			if (ix > -1) { // edit document
				this.$set(this.documentList, ix, json.data.file)
				this.showSuccess('Agent document replaced successfully<br>')
			} else { // new document
				this.documentList = [json.data.file, ...this.documentList]
				this.currentDoc = {
					...json.data.file,
					DocTitle: this.currentDoc.DocTitle || json.data.file.DocTitle,
					DocDesc: this.currentDoc.DocDesc || json.data.file.DocDesc,
					DocumentTags: this.currentDoc.DocumentTags?.length ? this.currentDoc.DocumentTags : json.data.file.DocumentTags,
					ExpDate: this.currentDoc.ExpDate || json.data.file.ExpDate,
				}
				this.showSuccess('Agent document uploaded successfully<br>')
			}
        },
        updateDocumentList () {
            this.loading = true
            QuilityAPI.getAgentDocumentList(this.agent.AgentCode, this.role)
				.then((json) => {
					this.$set(this, 'documentList', json.data)
				})
				.catch((err) => {
					this.showError(`${err.message}<br>`)
				})
				.finally(() => { this.loading = false })
        },
        previewDocument: function(doc) {
            if (doc.DocType == 'pdf') {
                this.showPDFPreview = true;
                this.showDocPreview = false;
            } else {
                this.showPDFPreview = false;
                this.showDocPreview = true;
                switch (doc.DocType) {
                    case 'doc':
                        this.previewDocumentDocType = "office"
                        break;
                    case 'docx':
                        this.previewDocumentDocType = "office"
                        break;
                    case 'txt':
                        this.previewDocumentDocType = "text"
                        break;
                    case 'csv':
                        this.previewDocumentDocType = "code"
                        break;
                    case 'jpg':
                        this.previewDocumentDocType = "image"
                        break;
                    case 'png':
                        this.previewDocumentDocType = "image"
                        break;
                    case 'gif':
                        this.previewDocumentDocType = "image"
                        break;
                    default:
                        this.previewDocumentDocType = "text"
                        break;
                }
            }
            if (process.env.VUE_APP_BASE_URL == 'http://localhost:8080') {
                this.previewDocumentUrl = doc.DownloadLink.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL)
                return
            }
            this.previewDocumentUrl = doc.DownloadLink;
        },
        getDocIcon: function(doctype) {
            switch (doctype) {
                case 'csv':
                    return 'fas fa-file-csv'
                case 'doc':
                    return 'fas fa-file-word'
                case 'xls':
                    return 'fas fa-file-excel'
                case 'xlsx':
                    return 'fas fa-file-excel'
                case 'txt':
                    return 'fas fa-file-text'
                case 'pdf':
                    return 'fas fa-file-pdf'
                case 'docx':
                    return 'fas fa-file-word'
                case 'png':
                    return 'fas fa-file-image'
                case 'jpg':
                    return 'fas fa-file-image'
            }
            return 'fas fa-file'
        },
        downloadDoc: function(doc) {
            if (process.env.VUE_APP_BASE_URL == 'https://localhost:8080') {
                window.open(doc.DownloadLink.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL), "_blank");
                return
            }
            window.open(doc.DownloadLink, "_blank");
        },
        editDoc (doc) {
			this.currentDoc = doc
				? {
					...doc,
					DocumentTags: doc.DocumentTags?.length ? [...doc.DocumentTags] : []
				}
				: {
					ID: null,
					DocTitle: '',
					DocDesc: '',
					DocumentTags: [],
					ExpDate: null,
				}
            this.showEditDoc = true
        },
        deleteDoc (doc) {
			this.removing = true
            QuilityAPI.deleteAgentDocument(doc.ID)
                .then((json) => {
					if (json.success === true) {
						const ix = this.indexByKey(doc.ID, this.documentList, 'ID')
						this.documentList.splice(ix, 1)
						this.showSuccess(`${json.message}<br>`)
					} else {
						this.showError(json.message)
					}
				})
                .catch((err) => {
					this.showError(`${err}<br>`)
				})
				.finally(() => {
					this.dialogConfirmDelete = false
					this.removing = false
				})
        },
		confirmDeleteDoc (doc) {
			this.currentDoc = { ...doc }
			this.dialogConfirmDelete = true
        },
        saveChanges () {
			this.saving = true
            QuilityAPI.updateAgentDocument(this.currentDoc)
				.then((json) => {
					const ix = this.indexByKey(this.currentDoc.ID, this.documentList, 'ID')
					this.$set(this.documentList, ix, json.file)
					this.showSuccess('Agent document updated successfully<br>')
					this.showEditDoc = false
				})
				.catch((err) => {
					this.showError(`${err.message}<br>`)
				})
				.finally(() => { this.saving = false })
        },
        closePreview () {
            this.previewDocumentUrl = ''
            this.previewDocumentDocType = ''
			this.showPDFPreview = false
			this.showDocPreview = false
        },
        removeTag (item) {
            this.currentDoc.DocumentTags.splice(this.currentDoc.DocumentTags.indexOf(item), 1)
        },
    },
    watch: {
        'agent.AgentCode' () {
			this.updateDocumentList()
			this.currentDoc = {}
        },
		showEditDoc () {
			if (!this.showEditDoc) {
				this.currentDoc = {}
			}
		},
		dialogConfirmDelete () {
			if (!this.dialogConfirmDelete) {
				this.currentDoc = {}
			}
		},
		currentDoc () {
			if (this.currentDoc.ID) {
				this.uploadMultipleDocs = false
			}
		},
		uploadMultipleDocs () {
			this.dropzoneKey += 1
		},
    },
    components: {
        QFileUpload,
		QDatePicker,
        VueDocPreview,
        QExpandableDataTable,
        QComplianceCorner,
        PdfPreview
    }
}
</script>

<style lang="scss" scoped>
.q-agent-documents-table {
    &__docs-preview {
        position: relative;
        z-index: 0;
    }

    &__preview-loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(50%);
        z-index: -1;
    }
}
</style>
