<template>
    <v-row wrap class="notes">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-col cols="4" v-for="(note, key) in notes" :key="key">
            <v-card color="yellow" height="300px" style="overflow-y: scroll">
                <v-card-title>{{ note.NoteTitle }}</v-card-title>
                <v-card-actions v-if="note.Link">
                    <v-btn :href="note.Link" target="_tab">Go</v-btn>
                </v-card-actions>
                <v-card-text>
                    <div>
                        <p class="notetext" v-html="formatNoteText(note.NoteText)"></p>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    props: ['agent'],
    data() {
        return {
            loading: false,
            notes: [],
        }
    },
    mounted: function() {
        this.updateNoteList();
    },
    computed: {

    },
    methods: {
        formatNoteText(text) {
            if (text == null) {
                return '';
            }
            return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
        updateNoteList() {
            var g = this
            this.loading = true;
            QuilityAPI.getAgentStickyNoteList(this.agent.AgentCode, this.role).then(function(json) {
                g.$set(g, 'notes', json.data)
                g.$emit('notes', json)
                g.loading = false;
            }).catch(function(err) {
                g.showError(err.msg)
                g.loading = false;
            })
        },
    },
    watch: {
        'agent.AgentCode': function(newV) {
            var g = this;
            this.$nextTick(function() {
                g.updateNoteList()
            })
        },
    },
    components: {

    }
}

</script>
