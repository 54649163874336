<template>
    <v-container fluid class="transparent">
        <v-data-table
            v-bind="$attrs"
            :headers="headers"
            :items="carriers"
            :mobile-breakpoint="0"
            :loading="loading"
            class="q-carrier-list"
        >
            <template #item.CarrierName="{ item }">
                <v-icon v-if="hightlightCoreCarrier && item.CoreCarrierInd == 1" left color="yellow">fas fa-star</v-icon>
                <router-link v-if="item.CarrierCMSURL" :to="item.CarrierCMSURL">
                    {{ item.CarrierName }}
                </router-link>
                <span v-else>{{ item.CarrierName }}</span>
            </template>

            <template #item.AgentPhone="{ item }">
                <a :href="`tel:${item.AgentPhone}`">{{ item.AgentPhone }}</a>
            </template>

            <template #item.Links="{ item }">
                <template v-if="!$vuetify.breakpoint.xs">
                    <v-btn color="primary" v-if="item.CarrierCMSURL" @click="clicked(item.CarrierCMSURL)" class="ma-1" small>
                        Info
                    </v-btn>
                    <v-btn v-if="item.AgentLoginURL" @click="clicked(item.AgentLoginURL)" class="ma-1" small>
                        Agent Login <v-icon right>fas fa-external-link-alt</v-icon>
                    </v-btn>
                    <v-btn v-if="item.CarrierURL" @click="clicked(item.CarrierURL)" class="ma-1" small>
                        Website <v-icon right>fas fa-external-link-alt</v-icon>
                    </v-btn>
                    <v-btn v-if="item.CarrierEAppURL" :href="item.CarrierEAppURL" class="ma-1" target="_blank" small>
                        E-App <v-icon right>fas fa-external-link-alt</v-icon>
                    </v-btn>
                </template>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
    name: "CarrierList",
    //when setting props
    props: {
        carriers: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
        hightlightCoreCarrier: { type: Boolean, default: false },
    },
    data: () => ({

    }),

    computed: {
        headers() {
            return [
                { text: 'Carrier', value: 'CarrierName' },
                { text: 'Phone', value: 'AgentPhone' },
                this.$vuetify.breakpoint.smAndDown ? undefined : { text: 'Fax', value: 'StaffFaxNumber' },
                { text: 'Links', value: 'Links' },
            ].filter((i) => i !== undefined);
        },
    },

    methods: {
        clicked: function(href) {
            if (href.indexOf('http') > -1) {
                if (href.indexOf('quility.com') > -1) {
                    document.location = href
                } else {
                    window.open(href, '_blank');
                }
            } else {
                this.$router.push(href)
            }
        }
    }
}

</script>

<style lang="scss">
.q-carrier-list {
    .v-data-table__mobile-table-row {
        td:first-child {
            margin-top: 1rem;
        }
        td:last-child {
            padding-bottom: 1rem;
        }
        td {
            width: 100%;
        }
    }
    tbody tr:nth-of-type(2n) {
        background-color: rgba(0, 0, 0, .03);
    }
}
</style>
