<template>
	<div class="bonus-item__row" :class="isMobileTableView && 'flex-wrap-reverse'">
		<div class="bonus-item__col-left">
			<BonusReportTable
				v-model="search"
				:bonus-type="bonusType"
				:items="items"
				:total-bonus="totalBonus"
				:filters="computedFilters"
				:options="options"
				:server-items-length="serverItemsLength"
				:loading="itemsLoadingStatus.loading"
				:error="itemsLoadingStatus.error"
				@search="searchData"
				@update-options="options = $event"
				@load-data="loadData"
				@export-results="exportResults"
			>
				<template v-if="bonusType === bonusTypes.sherpa" #item.Sherpa_AgencyProducingAgentName="{ item, value }">
					<router-link v-if="item.Sherpa_AgencyProducingAgentCode" :to="`/agents/${item.Sherpa_AgencyProducingAgentCode}`" class="bonus-item__table-link-btn">{{ value || 'go to agent details' }}</router-link>
					<span v-else>{{ value || 'NA' }}</span>
				</template>
			</BonusReportTable>
		</div>

		<div class="bonus-item__col-right">
			<div class="bonus-item__side">
				<BonusReportFilters
					v-model="filters"
					:bonus-type="bonusType"
					:disabled="itemsLoadingStatus.loading"
					class="bonus-item__filter-selector bonus-item__time-selector"
				/>

				<v-btn
					class="bonus-item__dispute-btn mt-8"
					text elevation="0" color="#1383EA"
					@click="$emit('submit-dispute', bonusType)"
				>
					Submit a Dispute Form
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import BonusReportFilters from './BonusReportFilters.vue'
import BonusReportTable from './BonusReportTable.vue'
import BonusItemMixin from './BonusItemMixin'

export default {
    components: {
		BonusReportFilters,
		BonusReportTable,
	},
	mixins: [BonusItemMixin],
}
</script>
