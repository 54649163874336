<!--
this component is used in both My Leads and Available Leads
-->
<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="titlelink"
        :sticky="false"
        standard-submenu
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QMyProfileSubmenu",
    data() {
        return {
            title: "My Account",
            titlelink: [{
                label: "",
                link: "",
            }],
            buttons: [{}],
        }
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
