<template>
    <v-text-field
        v-bind="$attrs"
        v-model="computedValue"
        :rules="[
            value => !!value || $props.requiredMessage,
            () => isLinkValid || $props.invalidLinkMessage
        ]"
    />
</template>

<script>
const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

export default {
  props: {
    value: String,
    isValid: Boolean,
    requiredMessage: { type: String, default: 'Field is required' },
    invalidLinkMessage: { type: String, default: 'This does not appear to be a valid URL' }
  },

  computed: {
    computedValue: {
      get() { return this.value },
      set(v) { this.$emit('input', v) }
    },
    isLinkValid() {
      return this.computedValue && pattern.test(this.computedValue)
    }
  },

  mounted() {
    this.$emit('update:isValid', this.isLinkValid)
  },

  watch: {
    isLinkValid: {
      handler(newValue) {
        this.$emit('update:isValid', newValue)
      },
      immediate: true
    }
  }
}
</script>