<template>
	<div v-if="items.length" class="bonus-card-wrapper" :class="computedClass">
		<div
			v-for="({ type, label, value, hasBonusData }) in items" :key="type"
			class="bonus-card"
			:class="type === currentBonusType && 'bonus-card--active'"
		>
			<div class="bonus-card__label">{{ label }}</div>
			<div class="bonus-card__value">
				<span v-if="value < 0">-</span>
				<span class="with-currency">{{ formatItem(value, hasBonusData) }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		currentBonusType: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false
		},
		error: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		computedClass () {
			return [
				this.isMobileView && 'bonus-card-wrapper--mobile',
				this.isTabletView && 'bonus-card-wrapper--tablet',
				this.loading && 'bonus-card-wrapper--loading',
				this.error && 'bonus-card-wrapper--error',
			]
		},
	},
	methods: {
		formatItem (value, hasBonusData) {
			const isNullish = [undefined, null, ''].includes(value)
			if (isNullish || !hasBonusData) { return 'NA' }
			return this.formatNumber(Math.abs(value), '(0,0)')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.bonus-card-wrapper {
	--gap: 2rem;
	--min-width-card: 12rem;
	--min-height-card: 5rem;
	--font-size-label: 1.25rem;
	--font-size-value: 1.5rem;
	--padding-card: 0.5rem 1rem;

	&--tablet {
		--min-width-card: 10rem;
	}

	&--mobile {
		--gap: 1rem;
		--min-width-card: calc(50% - 12px);
		--min-height-card: 4rem;
		--font-size-label: 1rem;
		--font-size-value: 1.25rem;
		--padding-card: 0.5rem 0.5rem;
	}

	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: var(--gap);

	&.bonus-card-wrapper--mobile {
		justify-content: center;
	}

	&.bonus-card-wrapper--loading,
	&.bonus-card-wrapper--error {
		.bonus-card__value {
			opacity: 0;
		}
	}

	.bonus-card {
		width: var(--min-width-card);
		height: var(--min-height-card);
		padding: var(--padding-card);
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: $border-radius;
		border: 1px solid $color-card-border;
		border-left: 1rem solid $color-card-border-left;
		background-color: $color-white;
		color: $color-black;
		overflow-x: hidden;

		&--active {
			border-left-color: $color-tabs-slider;
		}

		&__label {
			font-size: var(--font-size-label);
			line-height: 1;
			white-space: nowrap;
		}

		&__value {
			margin-top: 0.5rem;
			font-size: var(--font-size-value);
			font-weight: bold;
			line-height: 1;
			transition: opacity 0.2s ease-in-out;
		}
	}
}
</style>
