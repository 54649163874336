<template>
    <div>
        <v-card elevation="0">
            <v-container fluid class="ma-0 pa-0 q_leads_1" id="bulk_jobs_datatable">
                <v-row>
                    <v-col cols="3" sm="8" class="">
                        <p class="q-display-1 pb-0 pt-0 mt-0 mb-0 white--text">Hierarchy Change Log
                        </p>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-data-table dense :mobile-breakpoint="2" :items-per-page.sync="rows" :headers="the_headers" :items="all_data" item-key="id" class="elevation-0 row-pointer" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options">
                <template v-slot:item.ID="{ item }">
                    <span>
                        6 <v-icon v-if="item.AppsSubmittedFirst6Weeks >= 6">fas fa-check</v-icon>
                    </span>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "../QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QHierarchyAuditTable",
    props: ['agent'],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: {},
            options: {
                'sortBy': ['LastChangeDate'],
                'sortDesc': [true]
            },
            expanded: [],
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return [];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Date',
                    value: 'LastChangeDate',
                    align: "left"
                }, {
                    text: 'Upline',
                    value: 'Upline',
                    align: "left"
                },
                {
                    text: 'Changed By',
                    value: 'LastChangeBy',
                    align: "left"
                }
            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            QuilityAPI.getAgentHierarchyAuditLog(this.agent.AgentCode).then(function(json) {
                g.data_loading = false
                g.$set(g, 'the_data', json.data);
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
            this.firstPull = false;
        }, 200)
    },
    watch: {
        'agent.AgentCode': function() {
            this.$nextTick(this.refreshData)
        }
    },
    components: {}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.row-pointer tbody tr:hover {
    cursor: pointer;
}

</style>
