<template>
    <div class="q-agency-downline">
        <v-btn block @click="show_list = !show_list">
            {{name}}
            <v-progress-circular size="15" indeterminate v-if="items[0].loading"></v-progress-circular>
        </v-btn>
        <v-card v-if="show_list" height="300" flat class="scrolling">
            <v-treeview v-if="drilldown && items != null" :active.sync="active" :open.sync="open" item-children="downline" :load-children="fetchAgents" :items="items" item-key="AgentCode" item-text="AgentName" activatable color="blue" open-on-click transition return-object>
                <template v-slot:label="{item, active}">
                    <div :class="'agent-' + item.Status" @click="activeAgent(item)">{{item.AgentName}}
                        <v-progress-circular size="20" indeterminate v-if="item.loading"></v-progress-circular>
                    </div>
                </template>
                <template v-slot:prepend="{ item, open }">
                    <v-icon>
                        {{getAgentIcon(item.LeadershipLevel)}}
                    </v-icon>
                </template>
            </v-treeview>
            <v-list v-else>
                <v-list-item-group v-model="active">
                    <v-list-item v-for="(item, key) in items[0].downline" :input-value="item" :key="key">
                        <v-list-item-avatar>
                            <v-icon left small>
                                {{getAgentIcon(item.LeadershipLevel)}}
                            </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content :class="'agent-' + item.Status">
                            {{ item.AgentName }} <v-progress-circular size="20" indeterminate v-if="item.loading"></v-progress-circular>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </div>
</template>
<script>
import Agent from '@/store/Models/Agent'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: 'QAgencyTree',
    props: ['agent', 'type', 'name', 'drilldown'],
    data: function() {
        return {
            show_list: false,
            active: [],
            open: [],
            items: [{
                AgentName: this.name,
                AgentCode: this.agent.AgentCode,
                LeadershipLevel: this.agent.LeadershipLevel,
                downline: [],
                type: 'shop',
                loading: false
            }]
        }
    },
    mounted: function() {
        this.fetchAgents(this.items[0])
    },
    computed: {

    },

    methods: {
        getInactiveClass: function(status) {
            console.log(status)
        },
        fetchAgents: function(agent) {
            var g = this
            if (typeof agent.downline == 'undefined' || agent.downline == null || agent.downline.length == 0) {
                agent.loading = true
                QuilityAPI.getAgentDownline(agent.AgentCode, ['LeadershipLevel'], this.type, this.hasRole('AgencyOwner')).then(function(resp) {
                    for (var i = 0; i < resp.data.length; i++) {
                        resp.data[i].loading = false
                        resp.data[i].open = false
                        resp.data[i].downline = []
                    }
                    agent.loading = false
                    g.open.push(agent);
                    //g.active = [agent];
                    g.$set(agent, 'downline', resp.data);
                    //g.$emit('loaded', agent);
                }).catch(function(err) {
                    console.log(err)
                    agent.loading = false
                });
            }
        },
        activeAgent: function(agent) {
            this.active = [agent]
            this.$emit('loaded', agent);
        },
        getAgentIcon: function(level) {
            switch (level) {
                case 'Agency Owner':
                    return 'fas fa-store'
                    break;
                case 'Agency Director':
                    return 'fas fa-store'
                    break;
                case 'Regional Agency Director':
                    return 'fas fa-store-alt'
                    break;
                case 'Managing Vice President':
                    return 'far fa-building'
                    break;
                case 'Senior Vice President':
                    return 'fas fa-building'
                    break;
                case 'Executive Vice President':
                    return 'fas fa-building'
                    break;
                case 'Associate Partner':
                    return 'fas fa-city'
                    break;
                case 'Senior Partner':
                    return 'fas fa-city'
                    break;
                case 'Managing Partner':
                    return 'fas fa-city'
                    break;
                default:
                    return 'far fa-user'
            }
        }
    },
    watch: {
        'active': function(newV) {
            if (typeof this.items[0].downline[newV] != 'undefined') {
                this.$emit('loaded', this.items[0].downline[newV]);
            }

        }
    },
    components: {

    }
}

</script>
<style scoped>
.scrolling {
    overflow-y: scroll;
}

div.agent-Inactive {
    opacity: .3;
}

</style>
