<template>
    <div>
        <v-progress-linear indeterminate v-if="loading || updating"></v-progress-linear>
        <v-list dense>
            <v-list-group v-for="writingNumbers in grouped_writing_numbers" :key="writingNumbers.carrierID">
                <!-- Only shows first active writing number //-->
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title label class="carrier-name">
                            <span v-if="writingNumbers.count > 0">{{ writingNumbers.name }} and {{ writingNumbers.count }} more</span>
                            <span v-else>{{ writingNumbers.name }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon v-if="writingNumbers.activedata">
                        <v-tooltip left color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text v-bind="attrs" :disabled="isDisabled" v-on="on" @click.stop v-clipboard:copy="writingNumbers.activedata.WritingNumber" v-clipboard:success="handleCopyStatus" class="ml-2">#{{ writingNumbers.activedata.WritingNumber}}</v-btn>
                            </template>
                            <span>Click to copy</span>
                        </v-tooltip>
                        <v-tooltip left color="q_new_b_1" v-if="hasPermission('manage:Agent')">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" :disabled="isDisabled" v-on="on" small @click.stop="inactivateWritingNumber(writingNumbers.activedata)" class="ml-2">
                                    <v-icon x-small color="q_new_b_1" class="text--grey lighten-2">fa fa-check-square</v-icon>
                                </v-btn>
                            </template>
                            <span>Deactivate</span>
                        </v-tooltip>
                        <v-tooltip left color="q_new_b_1" v-if="hasPermission('manage:Agent')">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" :disabled="isDisabled" v-on="on" small @click.stop="editWritingNumber(writingNumbers.activedata)" class="ml-2">
                                    <v-icon x-small color="q_new_b_1" class="text--grey lighten-2">fas fa-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip left color="red" v-if="hasPermission('manage:Agent')">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" :disabled="isDisabled" v-on="on" small class="" icon color="red" @click.stop="deleteWritingNumber(writingNumbers.activedata)">
                                    <v-icon x-small>fas fa-minus-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </v-list-item-icon>
                </template>
                <!-- Active Writing number list //-->
                <v-list-item v-if="writingNumbers.hiddenactivedata.length > 0">
                    Additional Active Writing Numbers
                </v-list-item>
                <v-list-item v-else>
                    No Additional Active Writing Numbers
                </v-list-item>
                <div v-for="numberData in writingNumbers.hiddenactivedata" :key="numberData.ID">
                    <v-list-item-icon>
                        <v-tooltip left color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text v-bind="attrs" :disabled="isDisabled" v-on="on" v-clipboard:copy="numberData.WritingNumber" v-clipboard:success="handleCopyStatus" class="ml-2">#{{ numberData.WritingNumber}}</v-btn>
                            </template>
                            <span>Click to copy</span>
                        </v-tooltip>
                        <v-tooltip left color="q_new_b_1" v-if="hasPermission('manage:Agent')">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" :disabled="isDisabled" v-on="on" small @click.stop="inactivateWritingNumber(numberData)" class="ml-2">
                                    <v-icon x-small color="q_new_b_1" class="text--grey lighten-2">fa fa-check-square</v-icon>
                                </v-btn>
                            </template>
                            <span>Deactivate</span>
                        </v-tooltip>
                        <v-tooltip left color="q_new_b_1" v-if="hasPermission('manage:Agent')">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" :disabled="isDisabled" v-on="on" small @click.stop="editWritingNumber(numberData)" class="ml-2">
                                    <v-icon x-small color="q_new_b_1" class="text--grey lighten-2">fas fa-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip left color="red" v-if="hasPermission('manage:Agent')">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" :disabled="isDisabled" v-on="on" small class="" icon color="red" @click="deleteWritingNumber(numberData)">
                                    <v-icon x-small>fas fa-minus-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </v-list-item-icon>
                </div>
                <!-- Inactive Writing number list //-->
                <v-list-item v-if="writingNumbers.inactivedata.length > 0">
                    Inactive Writing Numbers
                </v-list-item>
                <v-list-item v-else>
                    No Inactive Writing Numbers
                </v-list-item>
                <div v-for="numberData in writingNumbers.inactivedata" :key="numberData.ID">
                    <v-list-item-icon>
                        <v-tooltip left color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text v-bind="attrs" :disabled="isDisabled" v-on="on" v-clipboard:copy="numberData.WritingNumber" v-clipboard:success="handleCopyStatus" class="ml-2">#{{ numberData.WritingNumber}}</v-btn>
                            </template>
                            <span>Click to copy</span>
                        </v-tooltip>
                        <v-tooltip left color="q_new_b_1" v-if="hasPermission('manage:Agent')">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" :disabled="isDisabled" v-on="on" small @click="activateWritingNumber(numberData)" class="ml-2">
                                    <v-icon x-small color="q_new_b_1" class="text--grey lighten-2">far fa-square</v-icon>
                                </v-btn>
                            </template>
                            <span>Activate</span>
                        </v-tooltip>
                        <v-tooltip left color="red" v-if="hasPermission('manage:Agent')">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" :disabled="isDisabled" v-on="on" small class="" icon color="red" @click="deleteWritingNumber(numberData)">
                                    <v-icon x-small>fas fa-minus-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </v-list-item-icon>
                </div>
                <v-divider class="mt-3 mb-5"></v-divider>
            </v-list-group>
        </v-list>
        <v-row>
            <v-divider class="mt-4 ml-5"></v-divider>
            <v-btn text color="q_new_b_1" left small v-if="!isDisabled" @click="addWritingNumber" class="mr-5">
                Add New Writing Number
            </v-btn>
        </v-row>
        <v-dialog v-model="showEditWritingNumber" width="500">
            <v-card class="pa-6 text-center" min-height="">
                <v-card-title>
                    <h4 class="q-display-1 pa-5 white--text text-center q_leads_1" style="min-width:100%;">
                        <span v-if="current_writing_number.ID == null">New Writing Number</span>
                        <span v-else>Edit Writing Number</span>
                    </h4>
                </v-card-title>
                <v-card-text v-if="carrier_list.length > 0">
                    <v-progress-linear indeterminate v-if="updating"></v-progress-linear>
                    <v-select label="Carrier" :items="carrier_list" :disabled="current_writing_number.ID != null" item-text="CarrierName" item-value="ID" v-model="current_writing_number.CarrierID" :value-comparator="checkCarrierID" :rules="[form_rules.required]"></v-select>
                    <v-text-field label="Writing Number" :rules="[form_rules.required]" v-model="current_writing_number.WritingNumber"></v-text-field>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-btn right depressed color="primary" class="mr-4" @click="saveWritingNumber">Save</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import moment from 'moment'
export default {
    name: "QAgentContractingStaff",
    props: ['agent', 'atype'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            loading: false,
            current_writing_number: {},
            showEditWritingNumber: false,
            carrier_list: [],
            updating: false,
            grouped_writing_numbers: [],
        }
    },
    mounted: function() {
        this.current_writing_number.NPN = this.atype == "agent" ? this.agent.NPN : this.agent.DoingBusinessAsNPN
        this.getWritingNumbers();
        this.getCarrierList()
    },
    computed: {
        'isDisabled': function() {
            return this.updating || this.loading || !this.agent.NPN
        }
    },
    methods: {
        getWritingNumbers() {
            this.loading = true;
            var g = this;
            if (this.atype == "agent") {
                var func = QuilityAPI.getCarrierWritingNumbers(this.agent.AgentCode)
            } else {
                func = QuilityAPI.getCarrierWritingNumbersAgency(this.agent.AgentCode)
            }
            func.then(function(resp) {
                if (resp.success !== false) {
                    resp.data.sort((a, b) => {
                        let cna = a.CarrierName,
                            cnb = b.CarrierName
                        if (cna < cnb) {
                            return -1;
                        }
                        if (cna > cnb) {
                            return 1;
                        }
                        return 0;
                    })
                    g.groupWritingNumbers(resp.data)
                } else {
                    g.showError(resp.reason);
                }
                g.loading = false;
            });
        },
        getCarrierList() {
            var g = this;
            QuilityAPI.getCarriers({ itemsPerPage: "all" }).then(function(resp) {
                if (resp.success !== false) {
                    resp.data.sort((a, b) => {
                        let cna = a.CarrierName,
                            cnb = b.CarrierName
                        if (cna < cnb) {
                            return -1;
                        }
                        if (cna > cnb) {
                            return 1;
                        }
                        return 0;
                    });
                    g.$set(g, 'carrier_list', resp.data)
                } else {
                    g.showError(resp.reason);
                }
            });
        },
        handleCopyStatus(status) {
            this.showInfo("Copied to clipboard")
        },
        handleCopyError(status) {
            this.showError("Opps, something went wrong. Select the deisred text and use CTRL+C to copy.")
        },
        addWritingNumber: function() {
            this.current_writing_number = {};
            this.current_writing_number.NPN = this.atype == "agent" ? this.agent.NPN : this.agent.DoingBusinessAsNPN
            this.showEditWritingNumber = true
        },
        editWritingNumber: function(item) {
            this.current_writing_number = item;
            this.showEditWritingNumber = true
        },
        saveWritingNumber: function() {
            //check required fields.
            if (this.current_writing_number.CarrierID == null || this.current_writing_number.WritingNumber == null) {
                return;
            }
            this.updating = true;
            var g = this;
            QuilityAPI.saveCarriertWritingNumber(this.current_writing_number).then(function(resp) {
                if (resp.error !== true) {
                    g.$set(g, 'current_writing_number', resp.data)
                    g.showEditWritingNumber = false
                    g.getWritingNumbers()
                    g.logActivity('SaveWritingNumber', g.current_writing_number)
                } else {
                    g.showError(resp.msg);
                }
                g.updating = false;
            });
        },
        checkCarrierID: function(a, b) {
            //writing_number has ID as a string and the carrier list id has an int.
            return parseInt(a) == parseInt(b)
        },
        deleteWritingNumber: function(item) {
            this.updating = true;
            var g = this;
            QuilityAPI.deleteCarriertWritingNumber(item.ID).then(function(resp) {
                if (resp.success === true) {
                    g.getWritingNumbers()
                    g.showEditWritingNumber = false
                    g.logActivity('DeleteWritingNumber', g.current_writing_number)
                } else {
                    g.showError(resp.msg);
                }
                g.updating = false;
            });
        },
        groupWritingNumbers: function(wnData) {

            // reset this field if we are repopulating
            // the writing numbers
            if (this.grouped_writing_numbers.length > 0) {
                this.grouped_writing_numbers = []
            }

            let idxmap = {}
            let idx = 0

            for (const key in wnData) {
                const element = wnData[key]
                const carrierID = element.CarrierID
                const carrierName = element.CarrierName
                const isActive = element.Active

                if (idxmap[carrierID] == undefined || idxmap[carrierID] == null) {
                    idxmap[carrierID] = idx
                    idx += 1
                }

                let wnKey = idxmap[carrierID]

                if (!this.grouped_writing_numbers[wnKey]) {
                    this.grouped_writing_numbers[wnKey] = {}
                    this.grouped_writing_numbers[wnKey].hiddenactivedata = []
                    this.grouped_writing_numbers[wnKey].inactivedata = []
                    this.grouped_writing_numbers[wnKey].name = carrierName
                    this.grouped_writing_numbers[wnKey].carrierID = carrierID
                    this.grouped_writing_numbers[wnKey].count = 0
                    this.grouped_writing_numbers[wnKey].activedata = isActive ? element : null
                } else {
                    this.grouped_writing_numbers[wnKey].count++
                }

                if (isActive) {
                    this.grouped_writing_numbers[wnKey].hiddenactivedata.push(element)
                } else {
                    this.grouped_writing_numbers[wnKey].inactivedata.push(element)
                }
            }

            // sort each carrier data set by the most recent modified date
            for (const carrierset of this.grouped_writing_numbers) {
                if (carrierset.hiddenactivedata.length == 0) {
                    continue
                }

                carrierset.hiddenactivedata.sort((a, b) => {
                    let timea = a.LastChangeDate
                    let timeb = b.LastChangeDate

                    if (moment(timea).isAfter(timeb)) {
                        return -1
                    }

                    if (moment(timeb).isAfter(timea)) {
                        return 1
                    }

                    return 0
                })

                carrierset.activedata = carrierset.hiddenactivedata.shift()
            }

            // sort the carriers dataset array by carrier name
            this.grouped_writing_numbers.sort((a, b) => {
                let cna = a.name,
                    cnb = b.name
                if (cna < cnb) {
                    return -1
                }
                if (cna > cnb) {
                    return 1
                }
                return 0
            })

        },
        inactivateWritingNumber: async function(item) {

            this.updating = true

            try {
                let updateRes = await QuilityAPI.inactivateCarrierWritingNumber(item.ID, this.agent.AgentCode)

                if (updateRes.success) {
                    this.getWritingNumbers()
                } else {
                    if (this.hasRole('SuperAdmin')) {
                        console.log(updateRes.error)
                    }
                    this.showError('There was an issue with updating the writing number')
                }
            } catch (error) {
                if (this.hasRole('SuperAdmin')) {
                    console.log(error)
                }
                this.showError('There was an issue with updating the writing number')
            }

            this.updating = false
        },
        activateWritingNumber: async function(item) {
            this.updating = true

            try {
                let updateRes = await QuilityAPI.activateCarrierWritingNumber(item.ID, this.agent.AgentCode)

                if (updateRes.success) {
                    this.getWritingNumbers()
                } else {
                    if (this.hasRole('SuperAdmin')) {
                        console.log(updateRes.error)
                    }
                    this.showError('There was an issue with updating the writing number')
                }
            } catch (error) {
                if (this.hasRole('SuperAdmin')) {
                    console.log(error)
                }
                this.showError('There was an issue with updating the writing number')
            }

            this.updating = false
        },
    },
    watch: {
        "agent.AgentCode": function(newV) {
            if (newV != null) {
                this.$nextTick(this.getWritingNumbers)
            }
        }
    },
}

</script>
<style scoped>
</style>
