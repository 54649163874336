import api from '../../../store/API/QuilityAPI'

export type Params = {
  startDate: string,
  endDate: string,
  statType: string,
  sortDesc: boolean,
  sortBy: string,
  page: number,
  itemsPerPage: number,
  search: string,
  needAvailableFilters: boolean,
  CarrierID: number | null
}

type BonusTypes = 'PCS' | 'QRS' | 'Sherpa' | 'BirdDog' | 'Capital' | 'Producer' | 'Slingshot' | 'Equity'

type ErrorResponse = {
	error: boolean,
	message?: string,
}

type BonusTotalsEntry = {
	BonusType: BonusTypes,
	TotalAmount: number,
	hasBonusData: boolean,
}

type BonusTotalsResponse = {
	data: BonusTotalsEntry[]
}

type BonusResponse = {
	data: Record<string, string | number | null>[],
	hasBonusData: boolean,
	bonusAmountSum: number,
}

type BirdDogBonusDetailsResponse = {
	data: Record<string, string | number | null>[],
}

export const loadBonusReportType = async (type: string, agentCode: string, params: Partial<Params>) => {
	return api.getRequest(`/api/bonus/${type}/${agentCode}`, params)
	    .then((response) => {
			return response as BonusResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: true, message } as ErrorResponse
	    })
}

export const loadBonusTotals = async (agentCode: string, params: Partial<Params>) => {
	return api.getRequest(`/api/bonus/bonus_totals/${agentCode}`, params)
	    .then((response) => {
			return response as BonusTotalsResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: true, message } as ErrorResponse
	    })
}

export const getBirdDogBonusDetails = async (bonusId: string) => {
	api.cancelQRequest('QGetBirdDogBonusDetails')
	return api.getRequest(`/api/agent_bonus/birddog_details/${bonusId}`, {}, 'QGetBirdDogBonusDetails')
	    .then((response) => {
			return response as BirdDogBonusDetailsResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: true, message } as ErrorResponse
	    })
}
