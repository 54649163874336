<!--
The parent component for adding a editing a new agent...     
Used as a popup and not a 'view' like the add agent form is. That's why we made it a component. 
//-->
<template>
    <div>
        <v-dialog ref="the_dialog" :value="value" @input="handlePopup" width="700px" max-width="70%">
            <v-card class="pa-6 text-center" min-height="">
                <h4 class="q-display-1 pa-5 white--text text-center q_leads_1">
                    Edit Agent Details
                </h4>
                <q-form-edit-agent ref="agentForm" :agent-code="agentcode" v-on:saved="emitSaved"></q-form-edit-agent>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QFormEditAgent from '@/components/agent_admin/QFormEditAgent.vue'

export default {
    props: ['value'],
    data() {
        return {
            showPopup: this.value
        }
    },
    computed: {
        'agentcode': function() {
            if (typeof this.$route.params.agentcode == 'undefined') {
                return null
            }
            return this.$route.params.agentcode;
        }
    },
    methods: {
        handlePopup(e) {
            this.$emit('input', e)
        },
        emitSaved() {
            this.$emit('input', false)
        }

    },
    watch: {

    },
    components: {
        QFormEditAgent
    }
}

</script>
