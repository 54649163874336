<template>
    <div>
        <v-dialog ref="the_dialog" :value="value" @input="handlePopup" :width="850">
            <v-card class="pa-6 text-left" min-height="">
                <v-row>
                    <v-col class="">
                        <h4 class="q-display-1 pa-5 white--text text-center q_leads_1">Default Login Information
                        </h4>
                    </v-col>
                </v-row>
                <p>Below are the default agent login credentials. These may not be current since we do not store updated passwords. If an agent updates their password, the credential below will no longer work. </p>
                <div style="max-width:400px;">
                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td>
                                    <pre>Agent Name:</pre>
                                </td>
                                <td>
                                    <pre>{{ agent.AgentName}}</pre>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <pre>Email:</pre>
                                </td>
                                <td>
                                    <pre>{{ agent.AgentEmail }}</pre>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <pre>Password:</pre>
                                </td>
                                <td>
                                    <pre>{{ agent_password }}</pre>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props: ['value', 'agent'],
    data() {
        return {
            showPopup: this.value
        }
    },
    computed: {
        'agent_password': function() {
            if (typeof this.agent.AgentName == 'undefined') {
                return '';
            }
            var s = this.agent.AgentName.split(' ');
            var lastname = s[s.length - 1].toLowerCase();
            if (lastname.length < 3) {
                lastname = s[s.length - 2].toLowerCase();
            }
            var password = lastname + '@' + this.agent.AgentCode;
            return password;
        }
    },
    methods: {
        handlePopup(e) {
            this.$emit('input', e)
        }
    },
    watch: {
        'value': function(newV) {}
    }
}
</script>