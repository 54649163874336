<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <v-row class="mx-5">
            <v-progress-linear indeterminate v-if="user.Agent == null"></v-progress-linear>
            <v-col cols=12 v-else>
                <h2>
                    {{user.Agent.AgentName}} Account Balance : {{ formatCurrency(balance) }}
                    <v-progress-circular size="30" color="primary" indeterminate v-if="loading_balance"></v-progress-circular>
                    <q-account-topoff :agent-code="user.Agent.AgentCode" :balance="balance" :topoff-amount="topoff_amount" v-on:balance="loadBalance"></q-account-topoff>
                </h2>
                <q-agent-payment-profiles-data-table ref="payment_profiles" :agentCode="user.Agent.AgentCode" title="Saved Payment Profiles" v-on:balance="loadBalance"></q-agent-payment-profiles-data-table>
                <q-agent-account-ledger-data-table ref="ledger" :agentCode="user.Agent.AgentCode" title="Account Ledger" v-on:balance="loadBalance" v-on:savecard="savecardPrompt"></q-agent-account-ledger-data-table>
            </v-col>
        </v-row>
        <q-save-payment-profile :agent-code="user.Agent.AgentCode" v-model="show_payment_form"></q-save-payment-profile>
    </v-container>
</template>
<script>
//table data + search
import QAgentAccountLedgerDataTable from '@/components/datatables/AgentAccounts/QAgentAccountLedgerDataTable.vue';
import QAgentAccountsAdminSubmenu from '@/components/navigation/Submenus/QAgentAccountsAdminSubmenu.vue';
import QAgentPaymentProfilesDataTable from '@/components/datatables/AgentAccounts/QAgentPaymentProfilesDataTable.vue';
import QSavePaymentProfile from '@/components/authorizenet/QSavePaymentProfile.vue';
import QAccountTopoff from '@/components/authorizenet/QAccountTopoff.vue';

import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    data() {
        return {
            agent: null,
            loading: false,
            balance: null,
            topoff_amount: 0,
            loading_balance: false,
            show_payment_form: false,
            balance_data: null
        }
    },
    mounted: function() {
        this.getAgent();
    },
    components: {
        QAgentAccountsAdminSubmenu,
        QAgentAccountLedgerDataTable,
        QSavePaymentProfile,
        QAgentPaymentProfilesDataTable,
        QAccountTopoff
    },
    methods: {
        getAgent() {
            var g = this
            g.loading = true
            QuilityAPI.getAgentStateless(this.user.Agent.AgentCode).then(function(json) {
                g.loading = false;
                g.agent = json;
                g.loadBalance()
            })
        },
        loadBalance: function() {
            var g = this
            g.loading_balance = true
            QuilityAPI.getAgentAccountBalance(this.user.Agent.AgentCode).then(function(json) {
                g.loading_balance = false;
                if (json.data) {
                    g.balance_data = json.data
                    g.topoff_amount = g.balance_data.TopOffAmount
                    g.balance = json.data.Balance;
                }
            })
        },
        savecardPrompt: function() {
            this.$refs.payment_profiles.show_payment_form = true
        }
    },
    watch: {

    },
}
</script>