import { Parser } from 'json2csv'

export default {
    methods: {
		exportToCSV (fields = [], data = [], filename = 'ExportResults') {
            const opts = { fields }
            try {
                const parser = new Parser(opts)
                const csv = parser.parse(data)
                const fileURL = window.URL.createObjectURL(new Blob([csv]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                fileLink.setAttribute('download', this.slugify(filename) + '.csv')
                document.body.appendChild(fileLink)
                fileLink.click()
            } catch (err) {
                console.error(err)
            }
		},
    },
}
