<template>
    <div v-if="badges.length > 0" class="q-user-badges">
        <v-tooltip bottom v-for="badge in badges" :key="badge.img">
            <template v-slot:activator="{ on, attrs }">
                <v-img v-bind="attrs" v-on="on" :src="badge.img" contain class="q-user-badges__badge" />
            </template>
            <span>{{ badge.tooltip }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: 'UserBadges',

    props: {
        agent: { type: Object, required: true },
    },

    computed: {
        slingshotBadge() {
            if (['regular', 'golden'].includes(this.agent.SlingshotBadges)) {
                return {
                    img: this.slingshotBadgeUrl(this.agent.SlingshotBadges),
                    tooltip: 'Slingshot Badge'
                }
            }
            return undefined
        },
        
		summitBadge() {
			return this.agent.CurrentSummitBadge ? {
				img: this.agent.CurrentSummitBadge.micro,
				tooltip: this.agent.CurrentSummitBadge.name || 'Summit Badge'
			} : undefined;
		},

        advisoryBadge() {
            if (!this.agent.AdvisoryBoardMemberDetail) { return }

            if (this.agent.AdvisoryBoardMemberDetail.toLowerCase() === 'legacy') {
                return {
                    img: 'https://hq-image-cdn.azureedge.net/servers/production/cms_images/AS-RQ_154_22-04-07_Legacy-AB-Signature-Badge_74x39_LncRg7.png',
                    tooltip: 'Legacy Advisory Board Badge'
                }
            }

            return {
                img: 'https://hq-image-cdn.azureedge.net/servers/production/cms_images/AS-RQ_154_22-04-07_AB-Signature-Badge_74x33_OAmH6W.png',
                tooltip: 'Advisory Board Badge'
            }
        },

        annuityBadge() {
            if (this.hasAllowedStatus && this.agent.AnnuityCertifiedInd) {
                return {
                    img: 'https://hq-image-cdn.azureedge.net/servers/production/cms_images/22-10-13_Annuity-certified_Badge_yLKHnG.svg',
                    tooltip: 'Annuity Certified Badge'
                }
            }
            return undefined
        },

        iulBadge() {
            if (this.hasAllowedStatus && this.agent.IULCertifiedInd) {
                return {
                    img: 'https://hq-image-cdn.azureedge.net/servers/production/cms_images/22-10-13_IUL-certified_Badge_zOnFGX.svg',
                    tooltip: 'IUL Certified Badge'
                }
            } 
            return undefined
        },

		ibcBadge() {
			return (this.hasAllowedStatus && this.agent.IBCCertifiedInd) ? {
				img: 'https://hq-image-cdn.azureedge.net/servers/production/cms_images/23-11-08_IBC-HQ-Badge_eg9557.svg',
				tooltip: 'IBC Certified Badge'
			} : undefined;
		},

        dflBadge() {
            return (this.hasAllowedStatus && this.agent.DFLCertifiedInd) ? {
                img: 'https://hq-image-cdn.azureedge.net/servers/production/cms_images/22-10-13_DFL-certified_Badge_0J1TKI.svg',
                tooltip: 'DFL Certified Badge'
            } : undefined
        },

        navigatorAccessBadge() {
            return this.agent.NavigatorAccess ? {
                img: 'https://hq-image-cdn.azureedge.net/servers/production/cms_images/navigator_badge_Large_KtbdJn.svg',
                tooltip: 'Navigator Course Certification Badge'
            } : undefined
        },

        badges() {
            return [this.slingshotBadge, this.summitBadge, this.advisoryBadge, this.annuityBadge, this.ibcBadge, this.iulBadge, this.dflBadge, this.navigatorAccessBadge].filter(Boolean)
        },

		hasAllowedStatus () {
			const disallowedStatuses = ['Lapsed', 'Stalled', 'Terminated']
			return !disallowedStatuses.includes(this.agent.Status)
		},
    }
}
</script>

<style lang="scss">
$badge-size: 4rem;
$gap-between-badges: 0.5rem;

.q-user-badges {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    &__badge {
        flex: unset;
        height: $badge-size;
        width: $badge-size;
        margin: 0 $gap-between-badges;
    }
}
</style>