import api from '../../../store/API/QuilityAPI'

export type Filters = {
  // showDownline: boolean,
  search: string,
}

export type MetricValues = {
  "AgentName": string,
  "OnboardedAgents": number,
  "ActiveAgents": number,
  "ActiveWriters": number,
  "Writers": number,
  "AgentCode": string,
  "AgentID": number,
  "MonthlyAPVPerWriter": number,
  "MonthlyAppsPerWriter": number,
  "ActiveWritersPerWriter": number,
  "SNAsPerWriter": number,
  "SNAs": number,
  "TotalApps": number,
  "TotalAPV": number,
  "MonthlyAPVPerAgent": number,
  "MonthlyAppsPerAgent": number,
  "WritersPerAgent": number,
  "ActiveWritersPerAgent": number,
  "SNAsPerAgent": number,
  "PerWriter": number
}

export const getMetricValues = ({ agentCode }: {agentCode: string} & Partial<Filters>) => {
  return api.getRequest(`/api/private/stats/agent/${agentCode}/ao_recruiting_dashboard/metric_values`).then((response) => response.data as MetricValues[]) 
}

export type AgencyMetricValues = {
  "AgentName": string,
  "AgentCode": string,
  "AgentID": number,
  "APV_PerAgent": number,
  "AppsPerAgent": number,
  "Writer": number,
  "Average": number
}

export const getAgencyCentricMetric = ({ agentCode }: {agentCode: string} & Partial<Filters>) => {
  return api
    .getRequest(`/api/private/stats/agent/${agentCode}/ao_recruiting_dashboard/agency_centric`)
    .then((response) => response.data as AgencyMetricValues[])
    .then((data) => data.sort((a, b) => b.APV_PerAgent - a.APV_PerAgent))
}

export type RecruitCentricMetrics = {
  "AgentName": string,
  "AgentCode": string,
  "AgentID": number,
  "WritersPerAgent": number,
  "ActiveWritersPerAgent": number,
  "SNAsPerAgent": number,
  "Average": number
}

export const getRecruitCentricMetrics = ({ agentCode }: {agentCode: string} & Partial<Filters>) => {
  return api.getRequest(`/api/private/stats/agent/${agentCode}/ao_recruiting_dashboard/recruit_centric`).then((response) => response.data as RecruitCentricMetrics[])
}

export type WriterCentricMetrics = {
    "AgentName": string,
    "AgentCode": string,
    "AgentID": number,
    "MonthlyAPVPerWriter": number,
    "MonthlyAppsPerWriter": number,
    "ActiveWritersPerWriter": number,
    "SNAsPerWriter": number,
    "Average": number
}

export const getWriterCentricMetrics = ({ agentCode }: {agentCode: string} & Partial<Filters>) => {
  return api.getRequest(`/api/private/stats/agent/${agentCode}/ao_recruiting_dashboard/writer_centric`).then((response) => response.data as WriterCentricMetrics[])
}