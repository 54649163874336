export const bonusTypes = {
	pcs: 'PCS',
	qrsFif: 'QRS',
	sherpa: 'Sherpa',
	birdDog: 'BirdDog',
	capital: 'Capital',
	producer: 'Producer',
	slingshot: 'Slingshot',
	equity: 'Equity',
}

export const optionTypes = {
	personal: { label: 'Personal', value: 'Personal' },
	baseshop: { label: 'Baseshop', value: 'Baseshop' },
	totalAgency: { label: 'Total Agency', value: 'TotalAgency' },
}

const getStatOptions = () => Object.values(optionTypes)

export const bonusTypesStatOptions = [
	{ type: bonusTypes.capital, options: getStatOptions() },
	{ type: bonusTypes.producer, options: getStatOptions() },
	{ type: bonusTypes.slingshot, options: getStatOptions() },
]

export const bonuses = [
	{ type: bonusTypes.pcs, label: 'PCS' },
	{ type: bonusTypes.qrsFif, label: 'QRS & FIF' },
	{ type: bonusTypes.sherpa, label: 'Sherpa' },
	{ type: bonusTypes.birdDog, label: 'Bird-Dog' },
	{ type: bonusTypes.capital, label: 'Capital' },
	{ type: bonusTypes.producer, label: 'Producer' },
	{ type: bonusTypes.slingshot, label: 'Slingshot' },
	// { type: bonusTypes.equity, label: 'Equity' },
]

// additional options:
// - for one of rows:	sortBy: true (initial sort)
// - for any row:		visible: false (the column will be shown in the expandable row on all screens)
// - for any row:		mobileVisible: true (the column will be displayed on the mobile screen)
// - for one of rows:	summable: true (summable column to display the total bonus)
const pcs = [
	{ label: 'Agent Name', key: 'AgentName', mobileVisible: true },
	// { label: 'Writing Agent', key: 'WritingAgentName', mobileVisible: true },
	{ label: 'Policy Holder Name', key: 'InsuredName' },
	{ label: 'Carrier', key: 'OrigCarrier' },
	{ label: 'Product', key: 'OrigProduct' },
	{ label: 'Accounting Cycle', key: 'PeriodEndDate', type: 'date', format: 'YYYY-MM-DD hh:mm:ss', toFormat: 'MMMM' },
	{ label: 'Transaction Date', key: 'TransactionDate', type: 'date', format: 'YYYY-MM-DD hh:mm:ss', toFormat: 'YYYY-MM-DD' },
	{ label: 'Earned Credit', key: 'PaymentAmount', type: 'currency', mobileVisible: true, summable: true },
]

const qrsFif = [
	{ label: 'Policy #', key: 'PolicyNumber', mobileVisible: true },
	{ label: 'Carrier', key: 'OrigCarrier' },
	{ label: 'Insured', key: 'InsuredName' },
	{ label: 'Writing Agent', key: 'AgentName' },
	// { label: 'Writing Agent', key: 'WritingAgentName' },
	{ label: 'Transaction Date', key: 'TransactionDate', type: 'date', format: 'YYYY-MM-DD hh:mm:ss', toFormat: 'YYYY-MM-DD' },
	{ label: 'Commission Amount', key: 'PaymentAmount', type: 'currency', mobileVisible: true, summable: true },
]

const sherpa = [
	{ label: 'Payee', key: 'AgentName', mobileVisible: true },
	{ label: 'Agent Name', key: 'Sherpa_AgencyProducingAgentName' },
	// { label: 'Sherpa Agency producing APV', key: 'Sherpa_AgencyProducingAPV' },
	// { label: 'Carrier', key: 'OrigCarrier' },
	// { label: 'Carrier APV', key: 'APV', type: 'currency' },
	{ label: 'Sherpa Bonus', key: 'PaymentAmount', type: 'currency', mobileVisible: true, summable: true },
]

export const BirdDogRole = { agent: 'agent', admin: 'admin' }
const birdDog = [
	{ label: 'Placed Date', key: 'BirdDog_PlacedDate', type: 'date', toFormat: 'MM/DD/YYYY', sortBy: true, sortDesc: true },
	{ label: 'Q2A Agent', key: 'AgentName', disabledView: [BirdDogRole.agent], mobileVisible: true },
	{ label: 'Agent', key: 'AgentName', disabledView: [BirdDogRole.admin], mobileVisible: true },
	{ label: 'Agency Name', key: 'BirdDog_ProducingAgencyName' },
	{ label: 'Q2B Agent', key: 'BirdDog_ProducingAgentName', disabledView: [BirdDogRole.agent] },
	{ label: 'Policy #', key: 'PolicyNumber', disabledView: [BirdDogRole.agent] },
	{ label: 'Carrier', key: 'OrigCarrier' },
	{ label: 'Product', key: 'OrigProduct', disabledView: [BirdDogRole.agent] },
	{ label: 'APV', key: 'APV', type: 'currency' },
	{ label: 'Bonusable Premium', key: 'ActionablePremium', type: 'currency' },
	{ label: 'BDOG Percent', key: 'BirdDog_TotalPct', type: 'percent' },
	{ label: 'BDOG\u00A0%', key: 'BirdDog_Pct', type: 'percent' },
	{ label: 'Bonus\u00A0$', key: 'PaymentAmount', type: 'currency', mobileVisible: true },
	{ label: 'Upline\u00A0%', key: 'BirdDog_TotalUplinePct', type: 'percent' },
	{ label: 'Upline\u00A0$', key: 'BirdDog_TotalUplineAmount', type: 'currency' },
]

const capital = [
	{ label: 'Master', key: 'AgentName', mobileVisible: true },
	// { label: '2021 Growth to Beat', key: 'Capital_GrowthToBeatPrevYear2', type: 'currency' },
	{ label: '2022 Growth to Beat', key: 'Capital_GrowthToBeatPrevYear1', type: 'currency' },
	{ label: 'Total Net Placed', key: 'NetPlacedAmount', type: 'currency' },
	{ label: 'SFG Contract Level', key: 'Capital_ContractLevel' },
	{ label: 'PPL', key: 'PPL', type: 'currency' },
	{ label: 'Validating Downline AOs', key: 'Capital_ValidatingDownlineAOs', visible: false },
	{ label: 'PPL Share', key: 'Capital_PPLShare', visible: false },
	{ label: 'Growth Share', key: 'Capital_GrowthShare', visible: false },
	{ label: 'Validation Share', key: 'Capital_ValidationShare', visible: false },
	{ label: 'Downline AO Shares', key: 'Capital_DownlineAOShares', visible: false },
	{ label: 'Adjusted Shares', key: 'Capital_AdjustedShares', visible: false },
	{ label: 'Bonus per Share', key: 'Capital_BonusperShare', type: 'currency', visible: false },
	{ label: 'Finders Fee', key: 'Capital_FindersFee', type: 'currency' },
	{ label: 'Deducted Fees', key: 'Capital_DeductedFees', type: 'currency' },
	{ label: 'Total', key: 'PaymentAmount', type: 'currency', mobileVisible: true, summable: true },
]

const producer = [
	{ label: 'Agent', key: 'AgentName', mobileVisible: true },
	// { label: 'Agency Placed', key: 'Producer_AgencyPlaced', type: 'currency' },
	{ label: 'Net Placed Amount', key: 'NetPlacedAmount', type: 'currency' },
	{ label: 'Select Carriers', key: 'SelectCarriers', type: 'currency' },
	{ label: 'Weeks Wrote', key: 'WeeksWrote' },
	{ label: 'Production Goal', key: 'Producer_ProductionGoal', type: 'currency' },
	{ label: 'Smore Factor', key: 'Producer_SmoreFactor' },
	{ label: 'Debt', key: 'Producer_Debt', type: 'currency' },
	{ label: 'Bonus', key: 'PaymentAmount', type: 'currency', mobileVisible: true, summable: true },
]

const slingshot = [
	{ label: 'Agent', key: 'AgentName', mobileVisible: true },
	{ label: 'Agency Placed', key: 'NetPlacedAmount', type: 'currency' },
	{ label: 'Select Carriers', key: 'SelectCarriers', type: 'currency' },
	{ label: 'Weeks Wrote', key: 'WeeksWrote' },
	// { label: 'Factor Amount', key: 'Slingshot_FactorAmount', type: 'currency' },
	// { label: 'Factor', key: 'Slingshot_Factor' },
	// { label: 'Smore Bonus', key: 'Slingshot_SmoreBonus', type: 'currency' },
	{ label: 'Payment Amount', key: 'PaymentAmount', type: 'currency', mobileVisible: true, summable: true },
]

const equity = [
	{ label: 'Name', key: 'AgentName', mobileVisible: true },
	{ label: '130 Downline Life Total', key: 'Equity_SFGDirectDownlineLifeTotal', type: 'currency', visible: false },
	{ label: '130 Downline Annuity Total', key: 'Equity_SFGDirectDownlineAnnuityTotal', type: 'currency', visible: false },
	{ label: '130 Downline Placed Total', key: 'NetPlacedAmount', type: 'currency', visible: false },
	{ label: 'Percentage', key: 'Equity_Percentage', type: 'percent', visible: false },
	{ label: 'Percent of Total', key: 'Equity_PercentofTotal', type: 'percent', visible: false },
	{ label: 'Gerber Production', key: 'Equity_GerberProduction', type: 'currency', visible: false },
	{ label: 'Total 130 Bonus', key: 'Equity_TotalSFGDirectBonus', type: 'currency' },
	{ label: 'Equity Total Production', key: 'Equity_EquityTotalProduction', type: 'currency' },
	{ label: 'Downline 130 Production', key: 'Equity_DownlineSFGDirectProduction', type: 'currency', visible: false },
	{ label: 'Total Outside', key: 'Equity_TotalOutside', type: 'currency' },
	{ label: 'Shares', key: 'Equity_Shares', type: 'currency' },
	{ label: 'Equity Bonus', key: 'Equity_EquityBonus', type: 'currency' },
	{ label: 'Slingshot Investment', key: 'Equity_SlingshotInvestment', type: 'currency' },
	{ label: 'Finders Fee', key: 'Equity_FindersFee', type: 'currency' },
	{ label: 'Deducted Fees', key: 'Equity_DeductedFees', type: 'currency' },
	{ label: 'Total Equity/130 Bonus', key: 'PaymentAmount', type: 'currency', mobileVisible: true, summable: true },
]

export default {
	[bonusTypes.pcs]: pcs,
	[bonusTypes.qrsFif]: qrsFif,
	[bonusTypes.sherpa]: sherpa,
	[bonusTypes.birdDog]: birdDog,
	[bonusTypes.capital]: capital,
	[bonusTypes.producer]: producer,
	[bonusTypes.slingshot]: slingshot,
	[bonusTypes.equity]: equity,
}
