export default [{
        value: "0-1004",
        text: "Final Expense (0-1004)"
    },
    {
        value: "0-1007",
        text: "Mortgage Protection (0-1007)"
    },
    {
        value: "0-1007A",
        text: "Mortgage Acceleration (0-1007A)"
    },
    {
        value: "0-1010",
        text: "Disability (0-1010)"
    },
    {
        value: "0-1011",
        text: "Life (0-1011)"
    },
    {
        value: "0-1012",
        text: "Life-New Parent (0-1012)"
    },
    {
        value: "0-10075",
        text: "Spanish-MP (0-10075)"
    },
    {
        value: "0-1008",
        text: "Retirement Solutions (0-1008)"
    },
    {
        value: "0-1009",
        text: "Critical Illness (0-1009)"
    },
    {
        value: "0-1013",
        text: "Debt Free Life (0-1013)"
    }
]
