<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agent-accounts-admin-submenu></q-agent-accounts-admin-submenu>
        <v-row class="mx-5">
            <v-progress-linear indeterminate v-if="agent == null"></v-progress-linear>
            <v-col cols=12 v-else>
                <h2>
                    {{agent.AgentName}} Account Balance : {{ formatLongCurrency(balance) }}
                    <v-progress-circular size="30" color="primary" indeterminate v-if="loading_balance"></v-progress-circular>
                    <v-btn v-if="hasPermission('manage:AgentAccount')" @click="$refs.ledger.showLedgerEntry = true" class="mr-3">
                        Add Entry
                        <v-icon right>fas fa-plus-circle</v-icon>
                    </v-btn>
                    <q-account-topoff :agent-code="agent.AgentCode" :balance="balance" :topoff-amount="topoff_amount" v-on:balance="loadBalance"></q-account-topoff>
                </h2>
                <q-agent-account-ledger-data-table ref="ledger" :agentCode="this.$route.params.agentcode" title="Account Ledger" v-on:balance="loadBalance"></q-agent-account-ledger-data-table>
                <q-agent-payment-profiles-data-table ref="payment_profiles" :agentCode="this.$route.params.agentcode" title="Saved Payment Profiles" v-on:balance="loadBalance"></q-agent-payment-profiles-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QAgentAccountLedgerDataTable from '@/components/datatables/AgentAccounts/QAgentAccountLedgerDataTable.vue';
import QAgentPaymentProfilesDataTable from '@/components/datatables/AgentAccounts/QAgentPaymentProfilesDataTable.vue';
import QAgentAccountsAdminSubmenu from '@/components/navigation/Submenus/QAgentAccountsAdminSubmenu.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QAccountTopoff from '@/components/authorizenet/QAccountTopoff.vue';

export default {
    data() {
        return {
            agent: null,
            loading: false,
            balance: null,
            loading_balance: false,
            topoff_amount: 0,
            balance_data: null
        }
    },
    mounted: function() {
        this.getAgent();
    },
    components: {
        QAgentAccountsAdminSubmenu,
        QAgentAccountLedgerDataTable,
        QAgentPaymentProfilesDataTable,
        QAccountTopoff
    },
    methods: {
        getAgent() {
            var g = this
            g.loading = true
            QuilityAPI.getAgentStateless(this.$route.params.agentcode).then(function(json) {
                g.loading = false;
                g.agent = json;
                g.loadBalance()
            })
        },
        loadBalance: function() {
            var g = this
            g.loading_balance = true
            QuilityAPI.getAgentAccountBalance(this.$route.params.agentcode).then(function(json) {
                g.loading_balance = false;
                if (json.data) {
                    g.balance_data = json.data
                    g.topoff_amount = g.balance_data.TopOffAmount
                    g.balance = json.data.Balance;
                }
            })
        }
    },
    watch: {
        '$route.params.agentcode': function() {
            this.getAgent();
        }
    },
}
</script>