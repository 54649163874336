<template>
    <v-card width="700">
        <v-card-title>
            Messages
            <v-btn icon @click="getMessages">
                <v-icon small>fas fa-sync-alt</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-card-text style="height:500px;overflow:auto;" id="messages">
            <div
            v-for="message in messages"
            :key="message.sid"
            >
                <div>
                    <div class="text-caption grey--text" style="clear:both;" :style="message.direction == 'inbound' ? 'float:left;' : 'float:right;'">{{messageDateTime(message)}}</div>
                </div>
                <div
                    class="px-4 py-2 mb-4 white--text "
                    :class="message.direction == 'inbound' ? 'text-right grey darken-2' : 'text-left blue darken-2'"
                    style="display:inline-block; clear:both; border-radius:15px; max-width:80%;"
                    :style="message.direction == 'inbound' ? 'float:left;' : 'float:right;'"
                    v-html="formatBody(message.body)"
                >
                </div>
            </div>

        </v-card-text>
        <v-card-actions>
            <v-text-field
                v-model="message"
                hide-details="auto"
                outlined
                label="Message"
                append-icon="fas fa-paper-plane"
                @click:append="sendMessage"
                @keydown="messageKeydown"
            ></v-text-field>
        </v-card-actions>
    </v-card>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import moment from 'moment'

export default {
    props: ['agentCode'],
    data() {
        return {
            message: "",
            messages: [],
            loading: false
        }
    },
    mounted: function() {
        this.getMessages();
    },
    computed: {
        
    },
    methods: {
        getMessages: function() {
            this.loading = true
            let g = this
            QuilityAPI.getAgentSmsHistory(this.agentCode).then(function(json) {
                g.messages = json.Data
                g.loading = false
                setTimeout(function() {
                    g.scrollToBottom()
                }, 500)
            })
        },
        messageDateTime: function(message) {
            return moment.utc(message.date.date).local().format('MMMM Do YYYY, h:mm a')
        },
        formatBody: function(body) {
            let matches = body.match(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g);
            if(matches) {
                matches.forEach(url => {
                    body = body.replace(url, `<a href="${url}" class="white--text" target="_blank">${url}</a>`)
                })
            }
            body = body.replace(/\n\n/g, '<br />')
            return body
        },
        sendMessage: function() {
            if(this.message.length == 0)
                return

            this.loading = true

            let g = this
            QuilityAPI.sendSmsToAgent(this.agentCode, this.message).then(function(json) {
                g.getMessages()
            })
            this.message = ""
            this.scrollToBottom()
        },
        messageKeydown: function(e) {
            if(e.key == "Enter")
                this.sendMessage()
        },
        scrollToBottom: function() {
            var messagesDiv = document.getElementById("messages");
                messagesDiv.scrollTop = messagesDiv.scrollHeight;
        }
    },
    watch: {
        
    },
    components: {

    }
}
</script>