<template>
    <div>
        <QBusinessTimePeriodSelect
			v-model="datePeriod"
			:label="`Time Period for ${bonusLabel}`"
			:dont-reset="false"
			:allow-current="true"
			hide-time-period-switch
			:hide-all-option="!allowAllDatePeriod"
			earliest-date="2022-12-30"
			:disabled="disabled"
			color="#1383EA"
			calendar-dates
			:allowYTD="allowYTDDatePeriod"
		/>
        <div class="px-1">
            <small class="red--text" style="line-height: normal;">The time period above applies to the month the bonus was paid out, not to the month the policy was placed.</small>
        </div>
        <v-radio-group v-if="isOptionsAvailable" v-model="statType" :disabled="disabled" hide-details class="mt-8">
            <v-radio
				v-for="option in bonusOptions" :key="option.value"
				:value="option.value"
				:label="option.label"
				color="#1383EA"
			/>
        </v-radio-group>
    </div>
</template>

<script>
import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue'

import { optionTypes, bonusTypesStatOptions, bonuses } from '../config/bonusConfigs'

export default {
    components: {
        QBusinessTimePeriodSelect,
    },
    props: {
		value: {
			type: Object,
			required: true,
		},
		bonusType: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		allowAllDatePeriod: {
			type: Boolean,
			default: false,
		},
		allowYTDDatePeriod: {
			type: Boolean,
			default: false,
		},
	},
    data () {
        return {
            datePeriod: {
				startDateFormat: null,
				endDateFormat: null,
				period: 'month',
				label: 'Current Month',
			},
            statType: optionTypes.personal.value,
        }
    },
	created () {
		const { statType, ...datePeriodFields } = this.value
		this.datePeriod = { ...datePeriodFields }
        this.statType = statType
	},
    computed: {
		filters () {
			return {
				...this.datePeriod,
				statType: this.statType,
			}
		},
		isOptionsAvailable () {
			const bonusTypesWithOptions = bonusTypesStatOptions.map(({ type }) => type)
			const isAvailableBonus = bonusTypesWithOptions.includes(this.bonusType)
			const hasRole = this.hasRole(['AgencyOwner', 'SuperAdmin', 'Exec', 'Staff'])
			return isAvailableBonus && hasRole
		},
		bonusOptions () {
			if (!this.isOptionsAvailable) { return [] }
			return bonusTypesStatOptions
				.find(({ type }) => type === this.bonusType).options
				.map(options => ({ ...options }))
		},
		bonusLabel () {
			return bonuses.find(({ type }) => type === this.bonusType)?.label || this.bonusType
		},
    },
    watch: {
		filters () {
			this.$emit('input', this.filters)
		},
    },
}
</script>
