<template>
    <span>
        <v-btn @click="show_dialog = true">
            Top Off Account
            <v-icon right>fas fa-credit-card</v-icon>
        </v-btn>
        <v-dialog v-model="show_dialog" width="500">
            <v-card>
                <v-card-title>
                    Top Off Account
                </v-card-title>
                <v-card-text>
                    <p v-if="amount > 0">
                        Your current balance is {{ formatCurrency(balance) }}.<br>
                        <strong>Your credit card will be charged {{ formatCurrency(amount) }}</strong> to bring your account up to {{ formatCurrency(topoffAmount) }}. Please click "Process Payment" to complete the transaction.<br>
                        <v-btn color="primary" @click="topoffAccount">Process Payment
                            <v-progress-circular right size="20" color="white" indeterminate v-if="loading"></v-progress-circular>
                            <v-icon v-else right>fas fa-credit-card</v-icon>
                        </v-btn>
                    </p>
                    <p v-else>
                        Your account balance is already topped off. Please wait until charges have posted to your account.<strong>{{ formatCurrency(topoffAmount) }}</strong>
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </span>
</template>
<script>
import moment from 'moment'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "QAccountTopoff",
    props: ["agentCode", "balance", "topoffAmount"], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            show_dialog: false,
            loading: false,
            status: ""
        }
    },
    mounted: function() {

    },
    computed: {
        "amount": function() {
            return this.topoffAmount - this.balance
        }
    },
    methods: {
        topoffAccount: function() {
            var g = this
            g.loading = true
            //now send this to the laravel back end for charging the card. 
            QuilityAPI.topoffAgentAccountBalance(this.agentCode, this.amount).then(function(json) {
                g.loading = false;
                g.status = '';
                if (json.error) {
                    g.showError(json.msg);
                    return
                }
                g.showSuccess("Payment Complete!");
                g.$emit('balance');
                g.show_dialog = false;
            }).catch(function(err) {
                g.loading = false;
                g.status = '';
                g.showError(err);
            })
        },

    },
    watch: {

    },
}
</script>
<style scoped>
</style>