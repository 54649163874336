<template>
    <div>
        <v-card flat>
            <v-container fluid class="ma-0 pa-0" id="account_datatable'">
                <v-row class="q_leads_1">
                    <v-col cols="12" sm="6" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7">
                        <p class="q-display-3  pb-0 pt-0 mt-0 mb-0 white--text">
                            {{ title }}
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                        <p class="pt-0 mt-0 ">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filters
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-start" v-show="showFilters">
                    <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys" :checkboxesFilters="['CurrentPaymentMethodInd']" :show-reset="true"></q-filters>
                </v-row>
                <v-data-table :mobile-breakpoint="2" :items-per-page.sync="rows" :headers="the_headers" :items="all_data" item-key="ID" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" :search="search" show-expand :expanded.sync="expanded">
                    <!--adds view ticket icon -->
                    <template v-slot:item.Balance="{ item }">
                        {{ formatLongCurrency(item.Balance) }}
                    </template>
                    <template v-slot:item.Amount="{ item }">
                        <strong v-if="item.TransactionType == 'Pending'" class="grey--text"><em>{{ formatLongCurrency(item.Amount) }}</em></strong>
                        <strong v-else-if="item.Amount < 0" class="red--text">{{ formatLongCurrency(item.Amount) }}</strong>
                        <strong v-else>{{ formatLongCurrency(item.Amount) }}</strong>
                    </template>
                    <template v-slot:item.TransactionType="{ item }">
                        <div>
                            {{item.TransactionType}}
                            <v-btn class="ml-4" v-if="hasPermission('manage:AgentAccount') && item.ProcessorTransactionID != '' && item.ProcessorTransactionID != null && item.TransactionType != 'Credit Card Refund'"x-small @click="showRefundDialog(item)">Refund</v-btn>
                        </div>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-5">
                            <div class="pb-4" v-if="item.ProcessorTransactionID">
                                Transaction ID : {{item.ProcessorTransactionID}}
                            </div>
                            <div class="pb-4" v-if="item.Notes">
                                <pre>{{item.Notes}}</pre>
                            </div>
                            <div class="pb-4" v-if="item.OrderNumber">Lead Order #{{item.OrderNumber}}</div>
                            <div class="pb-4" v-if="item.LeadAssignmentID > 0">
                                {{item.LeadAssignment.LeadCode}} <br>
                                {{item.LeadAssignment.FirstName}} {{item.LeadAssignment.LastName}}<br>
                                {{item.LeadAssignment.City}}, {{item.LeadAssignment.State}} {{item.LeadAssignment.Zip}}
                            </div>
                        </td>
                    </template>
                    <template v-slot:body.append>
                        <tr>
                            <td colspan="12">
                                <v-btn @click="downloadData">Export</v-btn>
                                <v-progress-linear v-if="loading" class="mt-2" color="primary" indeterminate></v-progress-linear>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-container>
        </v-card>
        <v-dialog v-model="showLedgerEntry" width="60%">
            <v-card>
                <v-card-title>
                    <h3>Add Ledger Entry</h3>
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="warning != null" type="error">
                        {{ warning }}
                    </v-alert>
                    <v-alert v-if="ledger_entry.TransactionType == 'Deposit'" type="warning">
                        This will charge the agent's credit card!
                    </v-alert>
                    <v-form v-model="valid" ref="form" id="form">
                        <v-row wrap>
                            <v-col cols="6">
                                <v-text-field prepend-icon="fas fa-dollar-sign" label="Amount" v-model="ledger_entry.Amount" :rules="[form_rules.required, form_rules.number]"></v-text-field>
                                <v-select label="Category" v-model="ledger_entry.TransactionCategory" :items="category_items" :rules="[form_rules.required]"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select label="Type" v-model="ledger_entry.TransactionType" :items="['Deposit', 'Credit', 'Debit', 'Refund']" :rules="[form_rules.required]"></v-select>
                                <v-text-field label="Label" v-model="ledger_entry.TransactionLabel" :rules="[form_rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea label="Notes" v-model="ledger_entry.Notes"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-progress-linear indeterminate v-if="data_loading"></v-progress-linear>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-btn color="primary" @click="saveLedgerEntry(ledger_entry)">Save Ledger Entry</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showRefundEntry" width="60%">
            <v-card>
                <v-card-title>
                    <h3>Refund Card</h3>
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="warning != null" type="error">
                        {{ warning }}
                    </v-alert>
                    <v-alert type="warning">
                        This will refund the agent's credit card!
                    </v-alert>
                    <v-form v-model="valid" ref="form_refund" id="form">
                        <v-row wrap>
                            <v-col cols="6">
                                <v-text-field prepend-icon="fas fa-dollar-sign" label="Amount" v-model="refund.Amount" :rules="[form_rules.required, form_rules.number]"></v-text-field>
                                <v-text-field label="Label" v-model="refund.TransactionLabel" :rules="[form_rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field disabled label="Transaction ID" v-model="refund.ProcessorTransactionID" :rules="[form_rules.required]"></v-text-field>
                                <v-select label="Category" v-model="refund.TransactionCategory" :items="category_items" :rules="[form_rules.required]"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea label="Notes" v-model="refund.Notes"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-progress-linear indeterminate v-if="data_loading"></v-progress-linear>
                    </v-form>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row wrap>
                        <v-col cols="12">
                            <v-btn color="primary" @click="refundLedgerEntry(refund)">Process Refund</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QGenericDataTableMixin from "../QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QAgentAccountLedgerDataTable",
    props: ['agentCode'],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'account',
            //use this to "preset" a filter
            filters: {

            },
            options: {
                'sortBy': ['CreateDate'],
                'sortDesc': [true]
            },
            expanded: [],
            the_data: [],
            data_filters: null,
            showLedgerEntry: false,
            showRefundEntry : false,
            ledger_entry: {
                TransactionCategory: null,
                TransactionType: 'Debit',
                TransactionLabel: null,
                Notes: null,
                Amount: null
            },
            valid: false,
            warning: null,
            refund : {
                LedgerID : null,
                ProcessorTransactionID : null,
                Amount : null,
                TransactionCategory : null,
                TransactionLabel: null,
            }
        }
    },
    mounted: function() {
        this.resetLedgerEntry();
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return ['TransactionCategory', 'TransactionType'];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Created',
                    value: 'CreateDate',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'Label',
                    value: 'TransactionLabel',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'Type',
                    value: 'TransactionType',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'Category',
                    value: 'TransactionCategory',
                    sortable: true,
                    align: "left"
                },
                {
                    text: 'Balance',
                    value: 'Balance',
                    sortable: true,
                    align: "right"
                },
                {
                    text: 'Amount',
                    value: 'Amount',
                    sortable: true,
                    align: "right"
                }

            ]
        },
        available_filters: function() {
            return this.data_filters
        },
        category_items: function() {
            return [
                "Manual Adjustment",
                "Other",
                "Leads",
            ]
        },
        label_items: function() {
            if (this.available_filters == null) {
                return []
            }
            var r = [];
            this.available_filters.TransactionLabel.forEach(function(item) {
                if (item != '-All-') {
                    r.push(item);
                }
            });
            return r
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            if (this.data_filters == null) {
                opt.needAvailableFilters = true
            }
            g.$emit('balance');
            QuilityAPI.getAgentAccountLedger(this.agentCode, this.filters, opt).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                if (typeof json.msg != 'undefined' && json.msg == "Please save a card to your profile!") {
                    g.$emit('savecard');
                    return
                }
                g.$set(g, 'the_data', json.data);
                g.total_num = json.meta.total
                if (g.data_filters == null && typeof json.meta != undefined && typeof json.meta.filters != undefined) {
                    g.data_filters = json.meta.filters
                }
            }).catch(function(err) {
                g.data_loading = false
                if (err.message == "Please save a card to your profile!") {
                    return
                }
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
            this.firstPull = false;
        }, 200),
        downloadData: debounce(function() {
            var endpoint = '/api/private/agents/' + this.agentCode + '/account_ledger';
            var params = {
                ...this.options,
                ...this.filters,
                'itemsPerPage': "csv",
            }
            this.dispatchJob({
                'method': "GET",
                'path': endpoint,
                'params': params,
                'JobName': "Account Ledger Export"
            })
        }),
        refresh: function() {
            this.the_data = [];
            this.refreshData();
        },
        showRefundDialog: function(ledger){
            this.showRefundEntry = true;
            this.refund = {
                ProcessorTransactionID : ledger.ProcessorTransactionID,
                LedgerID : ledger.ID,
                Amount : 0,
                TransactionCategory : null,
                TransactionLabel: null,
            }
        },
        viewAgentLedger: function(agent_code) {
            this.$router.push({ path: '/agents/' + agent_code + '/account_balance' })
        },
        resetLedgerEntry: function() {
            this.ledger_entry = {
                TransactionCategory: null,
                TransactionType: 'Debit',
                TransactionLabel: null,
                Notes: null,
                Amount: null
            }
        },
        saveLedgerEntry: function(refund) {
            var g = this
            if (!this.$refs['form'].validate()) {
                this.goToTop();
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                this.saving = false;
                return false;
            }
            this.data_loading = true
            QuilityAPI.addAgentAccountLedger(this.agentCode, this.ledger_entry).then(function(json) {
                g.data_loading = false;
                if (typeof json.data == 'undefined') {
                    g.showError("Could not save entry! Check your input.");
                    return;
                }
                if (json.data.TransactionType == "Error") {
                    g.showError("There was a problem charging the card. " + json.data.Notes);
                    g.refresh();
                    return;
                }
                g.showSuccess("Ledger Entry Saved!");
                g.refresh();
                g.resetLedgerEntry();
                g.showLedgerEntry = false
                g.$emit('balance');
            }).catch(function(err) {
                g.showError(err);
            })
        },
        refundLedgerEntry: function(ledger_entry) {
            var g = this
            if (!this.$refs['form_refund'].validate()) {
                this.goToTop();
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                this.saving = false;
                return false;
            }
            this.data_loading = true
            QuilityAPI.refundAgentAccountLedger(this.agentCode, this.refund).then(function(json) {
                g.data_loading = false;
                if (typeof json.data == 'undefined') {
                    g.showError("Could not save entry! Check your input.");
                    return;
                }
                if (json.data.TransactionType == "Refund Error") {
                    g.showRefundEntry = false
                    g.showError("There was a problem refunding the card. " + json.data.Notes);
                    g.refresh();
                    return;
                }
                g.showSuccess("Refund Complete!");
                g.refresh();
                g.resetLedgerEntry();
                g.showRefundEntry = false
                g.$emit('balance');
            }).catch(function(err) {
                g.showError(err);
            })
        },
        goToTop: function() {
            this.$vuetify.goTo('#form', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
    },
    watch: {
        'options': function() {
            this.refreshData();
        },
        'agentCode': function(newV) {
            if (typeof newV != 'undefined' && newV != null) {
                this.the_data = []
                this.refreshData();
            }
        },
    },
    components: {}
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>