<template>
    <div>
        <v-dialog ref="the_dialog" :value="value" @input="handlePopup" :width="850">
            <v-card class="text-center">
                <QTabs dark v-model="tab">
                    <v-tab>Agent Level</v-tab>
                    <v-tab>History</v-tab>
                    <v-tab @click=" handlePopup(false)">Close
                        <v-icon right>fas fa-times</v-icon>
                    </v-tab>
                </QTabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item class="pa-6">
                        <h4 class="font-weight-bold" style="position:relative">
                            <span v-if="AgentLevel.id == null">Set New</span>
                            <span v-else>Edit</span> Agent Level<br>
                        </h4>
                        <q-form-edit-agent-level ref="AgentLevelForm" v-model="AgentLevel" :agent="agent" @saved="savedAgentLevel" @silent-saved="updateHistory"></q-form-edit-agent-level>
                    </v-tab-item>
                    <v-tab-item class="pa-6">
                        <h4 class="font-weight-bold">
                            Agent Level History
                        </h4>
                        <q-agent-level-history ref="AgentLevelHistory" :agent="agent" v-on:load="loadLevel"></q-agent-level-history>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QFormEditAgentLevel from '@/components/agent_admin/QFormEditAgentLevel.vue'
import QAgentLevelHistory from '@/components/agent_admin/QAgentLevelHistory.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import moment from 'moment'
import QTabs from '../navigation/QTabs.vue'

export default {
    props: ['value', 'agent', 'agentLevelId'],
    data() {
        return {
            viewHistory: false,
            tab: 0,
            AgentLevel: {
                id: null,
                AgentCode: this.agent.AgentCode,
                LevelID: null,
                OverrideLevelID: null,
                StartDate: moment().format("YYYY-MM-DD"),
                EndDate: null,
                Notes: null,
                LockedInd: false,
                LevelType: null
            }
        }
    },
    methods: {
        handlePopup(e) {
            this.tab = 0;
            this.$emit('input', e)
        },
        reset() {
            var g = this;
            this.loading = false
            var level = {
                id: null,
                AgentCode: this.agent.AgentCode,
                LevelID: null,
                OverrideLevelID: null,
                StartDate: moment().format("YYYY-MM-DD"),
                EndDate: null,
                Notes: null,
                LockedInd: false
            }
            this.$set(this, 'AgentLevel', level);
            this.$nextTick(function() {
                if (g.$refs.AgentLevelForm) {
                    g.$refs.AgentLevelForm.reset();
                }
                if (g.$refs.AgentLevelHistory) {
                    g.$refs.AgentLevelHistory.reset();
                }
            })
        },
        loadLevel: function(level) {
            var g = this;
            this.tab = 0;
            this.$set(this, 'AgentLevel', level)
            this.$nextTick(function() {
                g.$refs.AgentLevelForm.reset();
            })
        },
        savedAgentLevel: function(level) {
            this.tab = 1
            this.reset()
        },
		updateHistory () {
			if (this.$refs.AgentLevelHistory) {
				this.$refs.AgentLevelHistory.reset()
			}
		},
    },
    watch: {
        'value': function(newV, oldV) {
            var g = this
            this.$nextTick(function() {
                g.tab = 0;
            })
            this.reset();
            if (newV === false) {
                this.$emit('input', false)
            }
        },
    },
    components: {
        QFormEditAgentLevel,
        QAgentLevelHistory,
        QTabs
    }
}

</script>
