<template>
    <div>
        <v-dialog ref="the_dialog" :value="value" @input="handlePopup" :width="850">
            <v-card class="pa-6 text-left" min-height="">
                <div>
                    <v-row>
                        <v-col cols="12" class="">
                            <h4 class="q-display-1 pa-5 white--text text-center q_leads_1">Agent Permissions
                            </h4>
                        </v-col>
                        <v-col cols="12">
                            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                            <v-simple-table v-if="permissions && permissions.length > 0">
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="item in permissions" :key="item.value">
                                            <td>{{ item.value }}</td>
                                            <td>{{ item.description }}</td>
                                            <td>
                                                <v-btn @click="deletePermission(item.value)">Remove</v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <div v-else>No permissions have been assigned.</div>
                        </v-col>
                        <v-col cols="8">
                            <v-select v-model="selected_permission" label="Available Permissions" :items="agent_permissions"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-btn @click="addPermission">Add Permission</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import moment from 'moment'

export default {
    name: 'QEditPermissions',
    props: ['value', 'agent', 'agentLevelId'],
    data() {
        return {
            permissions: null,
            loading: false,
            selected_permission: null
        }
    },
    mounted: function() {},
    methods: {
        handlePopup(e) {
            this.tab = 0;
            this.$emit('input', e)
        },
        reset() {
            this.loadPermissions();
        },
        loadPermissions: function() {
            var g = this;
            g.loading = true;
            QuilityAPI.getAgentPermissions(this.agent.AgentCode).then(function(json) {
                g.permissions = json.data;
                g.loading = false;
            }, function(err) {
                console.log('err', err)
                g.showError(err);
                g.loading = false;
            }).catch(function(err) {
                g.loading = false;
            })
        },
        addPermission: function() {
            var g = this;
            g.loading = true;
            QuilityAPI.addAgentPermission(this.agent.AgentCode, this.selected_permission).then(function(json) {
                g.permissions = json.data;
                g.loading = false;
            }, function(err) {
                g.showError(err);
                g.loading = false;
            }).catch(function(err) {
                g.loading = false;
            })
        },
        deletePermission: function(permission) {
            var g = this;
            g.loading = true;
            QuilityAPI.deleteAgentPermission(this.agent.AgentCode, permission).then(function(json) {
                g.permissions = json.data;
                g.loading = false;
            }, function(err) {
                g.showError(err);
                g.loading = false;
            }).catch(function(err) {
                g.loading = false;
            })
        }
    },
    computed: {
        'agent_permissions': function() {
            var r = [];
            this.permission_choices.forEach(function(i) {
                if (i.indexOf('agent:') == 0) {
                    r.push(i)
                }
            })
            return r;
        }
    },
    watch: {
        'value': function(newV, oldV) {
            var g = this
            this.$nextTick(function() {
                //g.tab = 0;
            })
            if (newV === false) {
                this.$emit('input', false)
            } else {
                this.reset();
            }
        },
    },
    components: {

    }
}

</script>
