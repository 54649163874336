import moment from 'moment'
import { debounce } from 'vue-debounce'

import { loadBonusReportType } from '../api/index'
import tableConfigs, { bonuses, optionTypes, bonusTypes } from '../config/bonusConfigs'

const serverDateFormat = 'YYYY-MM-DD'

const defaultFilters = {
	startDateFormat: null,
	endDateFormat: null,
	period: 'month',
	label: 'Current Month',
	statType: optionTypes.personal.value,
}

export default {
	props: {
		bonusType: {
            type: String,
            default: '',
        },
		agent: { type: Object },
	},
    data () {
        return {
			bonuses,
			bonusTypes,
			defaultFilters,
			serverDateFormat,

			search: '',
			filters: { ...defaultFilters },
			options: {
				sortBy: [],
				sortDesc: [],
				page: 1,
				itemsPerPage: 25,
			},
			serverItemsLength: undefined,

			totalBonus: 0,
			items: [],
			itemsLoadingStatus: {
				loading: false,
				error: false,
			},
        }
    },
	created () {
		this.updateOptions()
		this.loadData()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.mdAndDown && !this.isMobileView
		},
		isMobileTableView () {
			return this.isMobileView || this.isTabletView
		},
		title () {
			return bonuses.find(({ type }) => type === this.bonusType)?.label
		},
		config () {
			return tableConfigs[this.bonusType] || []
		},
		agentCode () {
			return this.agent ? this.agent.AgentCode : this.user.Agent.AgentCode
		},
		computedFilters () {
			const { period, label, startDateFormat, endDateFormat, statType } = this.filters
			let startDate = startDateFormat
			let endDate = endDateFormat
			if (label === 'YTD') {
				startDate = moment().startOf('year').format(serverDateFormat)
				endDate = moment().format(serverDateFormat)
			}
			if (label.startsWith('Current')) {
				startDate = period === 'month'
					? moment().startOf('month').format(serverDateFormat)
					: moment().startOf('week').format(serverDateFormat)
				endDate = period === 'month'
					? moment().endOf('month').format(serverDateFormat)
					: moment().endOf('week').format(serverDateFormat)
			}
			return { period, label, startDate, endDate, statType }
		},
		exportResultsParams () {
			return {
				startDate: this.computedFilters.startDate,
				endDate: this.computedFilters.endDate,
				statType: this.computedFilters.statType,
				...this.options,
				search: this.search,
				sortBy: this.options.sortBy.join(','),
				sortDesc: this.options.sortDesc.join(','),
				itemsPerPage: 'csv',
			}
		},
    },
	watch: {
		agentCode () {
			this.resetState()
			this.loadData()
		},
		filters () {
			this.options.page = 1
			this.loadData()
		},
		options: {
			deep: true,
			handler () {
				this.loadData()
			},
		},
	},
	methods: {
        loadData: debounce(async function () {
			const filters = {
				startDate: this.computedFilters.startDate,
				endDate: this.computedFilters.endDate,
				statType: this.computedFilters.statType,
			}
			const params = {
				...this.options,
				...filters,
				search: this.search,
			}
			this.setItemsLoadingStatus(true, false)
			const res = await loadBonusReportType(this.bonusType, this.agentCode, params)
			if (res.error) {
				this.totalBonus = 0
				this.items = []
				this.setItemsLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading ${this.title} Bonus data.`, res.message)
			} else {
				this.totalBonus = res.bonusAmountSum
				this.items = res.data
				this.serverItemsLength = res.meta?.total
				this.setItemsLoadingStatus(false, false)
			}
        }, 500),
		setItemsLoadingStatus(loading, error) {
			this.itemsLoadingStatus.loading = loading
			this.itemsLoadingStatus.error = error
		},
		updateOptions () {
			const item = this.config.find(({ sortBy }) => sortBy)
			this.options = {
				...this.options,
				sortBy: item?.key ? [item.key] : [],
                sortDesc: item?.sortDesc ? [item.sortDesc] : [false],
			}
		},
		searchData () {
			this.options.page = 1
			this.loadData()
		},
		resetState () {
			this.filters = { ...defaultFilters }
			this.options.page = 1
			this.search = ''
			this.serverItemsLength = undefined
			this.items = []
			this.totalBonus = 0
		},
		exportResults () {
			this.dispatchJob({
                method: 'GET',
                path: `/api/bonus/${this.bonusType}/${this.agentCode}`,
                params: this.exportResultsParams,
                JobName: `${this.bonusType} Bonus`,
            })
		},
	},
}
