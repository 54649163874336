<template>
    <div v-if="loaded">
        <page slug="switchboard-activate"></page>
    </div>
</template>
<script>
import Page from '@/CMS/views/index.vue'
import QuilityAPI from "@/store/API/QuilityAPI.js"
export default {
    name: "SwitchboardActivate",
    props: [], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            loaded: false
        }
    },
    mounted: async function() {
            const agentcode = this.$route.params.agentcode
            let locationID = null

            let sb_config = this.user.Agent?.SwitchboardConfig

            if(sb_config !== null && sb_config !== undefined){
                if(typeof sb_config == 'string')
                {
                    sb_config = JSON.parse(sb_config)
                }

                if('location_id' in sb_config)
                {
                    window.open(`https://switchboard.quility.com/v2/location/${sb_config.location_id}/dashboard`, "_blank")
                }
            }


            this.loaded = true
    },
    components: { Page },
}
</script>
<style scoped>
</style>